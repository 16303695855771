import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { UserResolver } from '@app/modules/user/user-resolver.service';
import { UserProfileComponent } from './user-profile.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'user/profile',
      component: UserProfileComponent,
      data: {
        title: extract('User Profile')
      },
      resolve: {
        user: UserResolver
      }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [UserResolver]
})
export class UserProfileRoutingModule {}
