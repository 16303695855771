<div class="listing-controls">
  <app-listing-filter
    [options]="colDefs"
    (sortOptionSelectedEventEmitter)="sortOptionSelected($event)"
    (sortDirectionSelectedEventEmitter)="toggleAscDesc($event)"
  ></app-listing-filter>
  <app-listing-search (searchFilterEventEmitted)="searchPatients($event)"></app-listing-search>
</div>
<table>
  <thead>
    <tr>
      <th class="centered">
        Discharge
      </th>
      <th>
        Patient
      </th>
      <th class="centered">
        Sex
      </th>
      <th class="centered">
        MR #
      </th>
      <th>
        Status
      </th>
      <th class="centered">
        Completed
      </th>
    </tr>
  </thead>
  <tbody *ngIf="patients && pageOfItems?.length; else noPatients">
    <tr
      *ngFor="let patient of pageOfItems; let idx = index"
      [routerLink]="'/call-queue/operations/' + patient.patientOperationId + '/patient/' + patient.patientId"
      class="active"
      [class.archived]="!patient.patientActive"
    >
      <td class="centered listing-body-cell">
        {{ patient.patientDischargeDate | date: 'MM/dd/YY' }}
      </td>
      <td class="listing-body-cell">
        <div class="badge-container">
          <ng-container *ngIf="patient.patientNotificationCount > 0">
            <span class="notification-badge"> </span>
          </ng-container>
        </div>
        <app-patient-avatar
          [type]="'circle'"
          [patient]="patient"
          *ngIf="idx < pageLength * pageSelected"
        ></app-patient-avatar>
        <div class="patient-name">{{ patient.patientFirstName }} {{ patient.patientLastName }}</div>
      </td>
      <td class="centered listing-body-cell">
        {{ patient.patientGender == 'male' ? 'M' : 'F' }}
      </td>
      <td class="centered listing-body-cell">
        {{ patient.patientMedicalRecordNumber }}
      </td>
      <td class="listing-body-cell">
        {{ patient.patientStatusLabel }}
      </td>
      <td class="centered listing-body-cell">
        {{ patient.patientGraduated ? 'Yes' : 'No' }}
      </td>
    </tr>
  </tbody>
</table>
<jw-pagination
  *ngIf="patientsFiltered && sorted"
  [pageSize]="pageLength"
  [items]="patientsFiltered"
  (changePage)="onChangePage($event)"
>
</jw-pagination>

<ng-template #noPatients>
  <tbody>
    <p style="font-family: Avenir">
      Sorry! No patients exist for this operation, or your search is too narrow!
    </p>
  </tbody>
</ng-template>
