<ng-container *ngIf="status.notification.saved == false; else sendNotification">
  <form *ngIf="createNotificationForm" [formGroup]="createNotificationForm">
    <ion-row *ngIf="notification" class="notification-container">
      <ion-title>Notification</ion-title>
      <img class="notification-icon-image" src="/assets/icon-notify-counter.png" alt="" />
    </ion-row>
    <ion-list *ngIf="notificationTypes" class="notification-options" lines="none" inset>
      <ion-radio-group formControlName="notificationTypeId">
        <ion-row>
          <ul class="notification-list-container">
            <li *ngFor="let notificationType of notificationTypes">
              <ion-item class="notification-list-choice">
                <ion-radio class="radio-button-notify" mode="ios" value="{{ notificationType.notificationTypeId }}">
                </ion-radio>
                <ion-label color="normal">{{ notificationType.notificationTypeLabel }}</ion-label>
              </ion-item>
            </li>
          </ul>
        </ion-row>
      </ion-radio-group>
    </ion-list>
    <ion-textarea
      [autoGrow]="true"
      class="notification-textarea"
      formControlName="notificationMessage"
      placeholder="Leave Your Notes Here"
    ></ion-textarea>
    <ion-row>
      <div class="send-container">
        <button class="secondary button" type="button" (click)="dismiss()">Cancel</button>
        <button
          [disabled]="createNotificationForm.invalid"
          class="primary button"
          type="button"
          (click)="saveNotification()"
        >
          Save
        </button>
      </div>
    </ion-row>
  </form>
</ng-container>
<ng-template #sendNotification>
  <ion-row class="notification-container">
    <ion-title class="ion-float-left">{{ notification.notificationTypeLabel }}</ion-title>
    <img class="notification-icon-image" src="{{ notification.notificationIconImage }}" alt="" />
  </ion-row>
  <ion-row>
    <div class="review-container">
      <form *ngIf="createNotificationForm" [formGroup]="createNotificationForm">
        <ion-list class="review-notification-list" lines="none" inset>
          <ion-item class="notification-review-item">
            <span class="label">Patient:&nbsp;</span>
            {{ notification.notificationPatientFirstName }}
            {{ notification.notificationPatientLastName }}
          </ion-item>
          <ion-item class="notification-review-item">
            <span class="label">Patient #:&nbsp;</span>
            {{ notification.notificationPatientMedicalRecordNumber }}
          </ion-item>
          <ion-item class="notification-review-item">
            <span class="label">Facility:&nbsp;</span>
            {{ notification.notificationOperationName }}
          </ion-item>
          <ion-item class="notification-review-item ion-text-wrap">
            <ion-label class="label ion-text-wrap"
              ><span class="label">Notify:&nbsp;</span>
              <ng-container *ngIf="notificationRecipients; else noRecipients">
                <span *ngFor="let notificationRecipient of notificationRecipients; let idx = index">
                  {{ notificationRecipient.notificationRecipientEmail
                  }}{{ idx + 1 == notificationRecipients.length ? '' : ', ' }}
                </span>
              </ng-container>
              <ng-template #noRecipients>
                Woops! No recipients have been set. Click
                <a href="/operations/" + {{ notification.notifcationOperationId }}>here</a>
                to go set some recipients!
              </ng-template>
            </ion-label>
          </ion-item>
          <ion-item class="notification-review-item">
            <span class="label">Date of Notification:&nbsp;</span> {{ todaysDate }}
          </ion-item>
          <ion-item class="notification-review-item">
            <span class="label">Message:&nbsp;</span>
          </ion-item>
          <ion-item class="notification-review-item-message">
            <ion-textarea [autoGrow]="true" formControlName="notificationMessage"></ion-textarea>
          </ion-item>
        </ion-list>
      </form>
    </div>
  </ion-row>
  <ion-row>
    <div class="send-container">
      <button class="ion-float-left secondary button edit" (click)="editNotification()">Back</button>
      <button class="ion-float-right primary button send" (click)="sendTheNotification()">Send</button>
    </div>
  </ion-row>
</ng-template>
