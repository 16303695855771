<div class="queue-controls">
  <h1 class="call-queue-headline">
    QUEUE [{{ mode.spanish ? spanishPatientsCount : operation.currentAssignedPatientCount }}]
  </h1>
  <section class="call-queue table-filter">
    <app-listing-filter
      [options]="colDefs"
      (sortOptionSelectedEventEmitter)="sortOptionSelected($event)"
      (sortDirectionSelectedEventEmitter)="toggleAscDesc($event)"
    ></app-listing-filter>
  </section>
</div>
<div class="call-queue-patient-list" *ngIf="patients$ | async">
  <ion-list lines="none">
    <ion-item
      color="transparent"
      *ngFor="let patient of pageOfItems"
      class="active patient-list-item"
      [class.todays-call]="checkDateGreaterThanEqualToToday(patient.patientNextCallScheduledTime)"
      [class.new-discharge]="patient.patientCallCount == 1"
      [routerLink]="'/call-queue/operations/' + patient.patientOperationId + '/patient/' + patient.patientId"
    >
      <div class="call-queue-number">
        {{ patient.patientCallCount - 1 }}
      </div>
      <div class="call-queue-avatar">
        <div class="badge-container">
          <ng-container *ngIf="patient.patientNotificationCount > 0">
            <span class="notification-badge"> </span>
          </ng-container>
        </div>
        <app-patient-avatar [patient]="patient" [type]="'circle'"></app-patient-avatar>
      </div>
      <div class="call-queue-patient-information">
        <span class="call-queue-patient-name">
          <a href="'/operations/' + {{ patient.patientOperationId }} + '/patient/' + {{ patient.patientId }}">
            {{ patient.patientFirstName }}
            {{ patient.patientLastName }}
          </a>
        </span>
        <span class="patient label-line"
          >Discharge: {{ patient.patientDischargeDate | date: 'MM/dd/yyyy':'UTC' }}
          &nbsp; Next Call:
          {{ patient.patientNextCallScheduledTime | date: 'MM/dd/yyyy':'UTC' }}
        </span>
      </div>
    </ion-item>
  </ion-list>
  <jw-pagination *ngIf="patients" [items]="patients" (changePage)="onChangePage($event)"></jw-pagination>
</div>
