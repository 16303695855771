<div class="patient-manager-sidebar">
  <div class="patient-manager-sidebar-calendar">
    <h4 class="todays-date">TODAY'S DATE</h4>
    <div class="caption">
      {{ todaysDateDay }}
    </div>
  </div>
  <div class="facility-dropdown-container">
    <a class="facility-dropdown-target">PATIENTS</a>
    <ng-container *ngIf="operationGroups">
      <div class="operation-group-container" *ngFor="let operationGroup of operationGroups; let i = index">
        <div *ngIf="operationGroup.operations?.length">
          <span class="operation-group" (click)="toggleOperationSidebarMenu(operationGroup)"
            >{{
              operationGroup.operationGroupShortName?.length
                ? operationGroup.operationGroupShortName
                : operationGroup.operationGroupName
            }}
            <i
              class="expand-facilities-trigger"
              [@turnArrow]="
                operationGroup.sidebarDropdownOpen ||
                operationGroup.operationGroupId == selected.operation?.operationGroupId
                  ? 'open'
                  : 'closed'
              "
            >
            </i>
            <ul
              class="group-operations"
              [class.show]="
                operationGroup.sidebarDropdownOpen ||
                operationGroup.operationGroupId == selected.operation?.operationGroupId
              "
              *ngIf="operationGroup.operations"
            >
              <li *ngFor="let operation of operationGroup.operations" [class.spanish]="operation.spanishSpeaking">
                <div class="operation-link-container" *ngIf="operation.operationActive">
                  <a
                    class="operation-link"
                    [routerLink]="'/operations/' + operation.operationId + '/patients'"
                    (click)="setActiveOperation(operation)"
                    [class.active]="activeOperationId == operation.operationId"
                  >
                    {{ operation.operationName | slice: 0:15
                    }}<span *ngIf="operation.operationName?.length > 16">
                      <!-- ... -->
                    </span>
                  </a>

                  <span class="current-patients">
                    {{ operation.currentAssignedPatientCount }}
                  </span>
                </div>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </ng-container>

    <span class="spanish-indicator" [routerLink]="'/patients/spanish'">SPANISH <i class="patient"></i></span>

    <ng-template #noOperations>
      You haven't been assigned any operations yet!
    </ng-template>
  </div>
</div>
