<ion-list lines="none" inset>
  <ion-item>
    <ion-label position="stacked" color="medium">
      First Name
    </ion-label>
    <ion-input></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked" color="medium">
      Middle Name
    </ion-label>
    <ion-input></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked" color="medium">
      Last Name
    </ion-label>
    <ion-input></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked" color="medium">
      Title / Position
    </ion-label>
    <ion-input></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked" color="medium">
      Email
    </ion-label>
    <ion-input></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked" color="medium">
      Country Code
    </ion-label>
    <ion-input></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked" color="medium">
      Area Code
    </ion-label>
    <ion-input></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked" color="medium">
      Phone #
    </ion-label>
    <ion-input></ion-input>
  </ion-item>
</ion-list>
