<ion-content>
  <ion-row>
    <ion-col class="patient-sidebar">
      <app-patient-manager-sidebar [user]="user"></app-patient-manager-sidebar>
    </ion-col>
    <ion-col class="patient-content">
      <ion-title>Patient Portal</ion-title>
      <form id="patient-form" *ngIf="patientForm" [formGroup]="patientForm" novalidate>
        <div class="patient-section-title">
          <i class="icon-patient"></i>
          <span class="patient-header" *ngIf="mode.add">New Patient</span>
          <span class="patient-header" *ngIf="mode.edit">Edit Patient</span>
        </div>
        <ion-list class="patient-primary-info-list" lines="none" inset formGroupName="patient">
          <ion-item class="patient-facility">
            <ion-label color="medium" position="stacked">Facility<span style="color:#f1624a">*</span></ion-label>
            <ion-select placeholder="Facility" class="select-facility" formControlName="operation">
              <ion-select-option
                *ngFor="let operation of operations"
                [selected]="operation.operationId == patient.patientOperationId"
                [value]="operation.operationId"
              >
                {{ operation.operationName }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item class="patient-medical-record-number">
            <ion-label color="medium" position="stacked">Patient # <span style="color:#f1624a">*</span></ion-label>
            <ion-input type="text" formControlName="patientMedicalRecordNumber" placeholder="Patient #"></ion-input>
          </ion-item>
          <ion-list lines="none" inset formGroupName="patientName">
            <ion-item class="patient-first-name">
              <ion-label color="medium" position="stacked">First Name <span style="color:#f1624a">*</span></ion-label>
              <ion-input
                type="text"
                formControlName="patientFirstName"
                placeholder="First Name"
                inputCapitalizeFirst
              ></ion-input>
            </ion-item>
            <ion-item class="patient-last-name">
              <ion-label color="medium" position="stacked">Last Name <span style="color:#f1624a">*</span></ion-label>
              <ion-input
                type="text"
                formControlName="patientLastName"
                placeholder="Last Name"
                inputCapitalizeFirst
              ></ion-input>
            </ion-item>
          </ion-list>
          <ion-item class="patient-dob">
            <ion-label color="medium" position="stacked">Birthday<span style="color:#f1624a">*</span></ion-label>
            <ion-input type="date" formControlName="patientDob" placeholder="Birthday"></ion-input>
          </ion-item>
          <ion-item class="patient-gender">
            <ion-radio-group class="patient-gender-radio" formControlName="patientGender">
              <ion-item class="patient-gender-male">
                <ion-radio class="radio-button" mode="ios" value="male"></ion-radio>
                <ion-label>Male</ion-label>
              </ion-item>
              <ion-item class="patient-gender-female">
                <ion-radio class="radio-button" mode="ios" value="female"></ion-radio>
                <ion-label>Female</ion-label>
              </ion-item>
            </ion-radio-group>
          </ion-item>
          <div class="patient-phone-container">
            <ion-item class="patient-country-code">
              <ion-label color="medium" position="stacked">+(1)</ion-label>
              <ion-input type="text" maxLength="2" formControlName="patientCountryCode" placeholder="1"></ion-input>
            </ion-item>
            <ion-item class="patient-area-code">
              <ion-label color="medium" position="stacked">(###)</ion-label>
              <ion-input
                type="text"
                mask="000"
                maxLength="3"
                formControlName="patientAreaCode"
                placeholder="000"
              ></ion-input>
            </ion-item>
            <ion-item class="patient-phone-number">
              <ion-label color="medium" position="stacked">Phone #</ion-label>
              <ion-input
                type="text"
                formControlName="patientPhoneNumber"
                maxLength="11"
                inputAddPhoneDashes
              ></ion-input>
            </ion-item>
          </div>
          <ion-item class="inline-patient-responsibility-container">
            <ion-item class="patient-is-hipaa">
              <ion-checkbox formControlName="patientHIPAA"></ion-checkbox>
              <ion-label>HIPAA</ion-label>
            </ion-item>
            <ion-item class="patient-is-responsible">
              <ion-checkbox formControlName="patientIsResponsibleParty"></ion-checkbox>
              <ion-label>Responsible Party</ion-label>
            </ion-item>
          </ion-item>
          <div class="language-container">
            <ion-item class="speaks-english">
              <ion-checkbox formControlName="patientSpeaksEnglish"></ion-checkbox>
              <ion-label>Doesn't Speaks English</ion-label>
            </ion-item>
            <ion-item
              class="fluent-language"
              [class.show]="patientForm.get('patient.patientSpeaksEnglish').value !== false"
            >
              <ion-label position="stacked">Fluent Language <span style="color:#f1624a">*</span></ion-label>
              <ion-select formControlName="patientFluentLanguage" placeholder="Fluent Language">
                <ion-select-option selected value="English">English</ion-select-option>
                <ion-select-option value="Spanish">Spanish</ion-select-option>
                <ion-select-option value="Navajo">Navajo</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <div class="section-header contacts">
            <i class="icon-contacts"></i>
            <span class="contact-header">Contacts</span>
          </div>
          <fieldset class="patient-contacts" formArrayName="patientContacts">
            <ng-template #loadingContacts>
              <span class="loading-contacts">
                Loading Patient Contacts...
              </span>
            </ng-template>
            <ng-container *ngIf="patientContacts; else loadingContacts">
              <ion-list
                lines="none"
                *ngFor="let patientContact of patientContacts; let idx = index"
                formGroupName="{{ idx }}"
                id="additionalPatientContact-{{ idx }}"
              >
                <div class="patient-contact-header-container">
                  <span class="patient-contact-header" *ngIf="idx == 0; else alternateContact">Primary Contact</span>
                  <ng-template #alternateContact>
                    <span class="patient-contact-header">Contact {{ idx + 1 }}</span>
                  </ng-template>

                  <!-- only show removal option if it's past the first index -->
                  <ion-item
                    *ngIf="idx > 0"
                    class="ion-float-right remove-contact-label"
                    (click)="removeAdditionalPatientContact(idx)"
                  >
                    Remove Contact
                  </ion-item>
                </div>
                <ion-item class="first-name">
                  <ion-label color="medium" position="stacked"
                    >First Name <span style="color:#f1624a">*</span></ion-label
                  >
                  <ion-input type="text" formControlName="patientContactFirstName" inputCapitalizeFirst> </ion-input>
                </ion-item>
                <ion-item class="last-name">
                  <ion-label color="medium" position="stacked">Last Name</ion-label>
                  <ion-input type="text" formControlName="patientContactLastName" inputCapitalizeFirst> </ion-input>
                </ion-item>
                <ion-item class="relationship">
                  <ion-label color="medium" position="stacked"
                    >Relationship <span style="color:#f1624a">*</span></ion-label
                  >
                  <ion-input type="text" formControlName="patientContactRelationship" inputCapitalizeFirst> </ion-input>
                </ion-item>

                <div class="phone-container">
                  <ion-item class="country-code">
                    <ion-label color="medium" position="stacked">+(1)</ion-label>
                    <ion-input type="text" maxLength="2" formControlName="patientContactCountryCode"></ion-input>
                  </ion-item>
                  <ion-item class="area-code">
                    <ion-label color="medium" position="stacked">(###)</ion-label>
                    <ion-input type="text" maxLength="3" formControlName="patientContactAreaCode" placeholder="000">
                    </ion-input>
                  </ion-item>
                  <ion-item class="phone-number">
                    <ion-label color="medium" position="stacked">Phone #</ion-label>
                    <ion-input
                      type="text"
                      inputAddPhoneDashes
                      maxLength="11"
                      formControlName="patientContactPhoneNumber"
                    ></ion-input>
                  </ion-item>
                  <ion-item class="patient-contact-order">
                    <ion-label color="medium" position="stacked">Order</ion-label>
                    <ion-input type="number" formControlName="patientContactOrder" [value]="idx + 1"></ion-input>
                  </ion-item>
                  <ion-item class="patient-contact-hipaa">
                    <ion-checkbox formControlName="patientContactHIPAABoolean"></ion-checkbox>
                    <ion-label color="medium" type="stacked">HIPAA</ion-label>
                  </ion-item>
                  <ion-item class="responsible-party">
                    <ion-checkbox formControlName="patientContactResponsiblePartyBoolean"></ion-checkbox>
                    <ion-label color="medium" type="stacked">Responsible Party</ion-label>
                  </ion-item>
                </div>
              </ion-list>
              <ion-item class="ion-float-right add-additional-contact" (click)="addAdditionalPatientContact()"
                >Add Contact</ion-item
              >
            </ng-container>
          </fieldset>

          <div class="section-header">
            <i class="icon-physician"></i>
            <span class="discharge-header">Primary Care Physician</span>
          </div>
          <fieldset class="insurance-info" formGroupName="primaryCarePhysician">
            <ion-list class="patient-physician" lines="none" inset>
              <ion-item class="primary-care-physician">
                <ion-label color="medium" position="stacked">Physician</ion-label>
                <ion-input formControlName="patientPhysicianName" inputCapitalizeFirst></ion-input>
              </ion-item>
              <ion-item class="primary-care-physician">
                <ion-label color="medium" position="stacked">Phone #</ion-label>
                <ion-input formControlName="patientPhysicianPhoneNumber"></ion-input>
              </ion-item>
            </ion-list>
          </fieldset>
          <div class="section-header">
            <i class="icon-insurance"></i>
            <span class="discharge-header">Insurance</span>
          </div>
          <fieldset class="insurance-info" formGroupName="insurance">
            <ion-list lines="none" inset>
              <ion-item class="primary-insurance">
                <ion-label color="medium" position="stacked">Primary Insurance</ion-label>
                <ion-input formControlName="primaryInsurance" inputCapitalizeFirst></ion-input>
              </ion-item>
            </ion-list>
          </fieldset>
          <div class="section-header">
            <i class="icon-discharge-calendar"></i>
            <span class="discharge-header">Discharge Info</span>
          </div>
          <fieldset class="patient-discharge" formGroupName="dischargeInfo">
            <ion-label class="discharged-label" color="medium" position="stacked">
              Discharged Against Medical Advice? <span style="color:#f1624a">*</span>
            </ion-label>
            <ion-item class="discharged-ama">
              <ion-radio-group formControlName="patientDischargedAma">
                <ion-item>
                  <ion-radio class="radio-button-yes discharged-ama" value="1"> </ion-radio>
                </ion-item>
                <ion-item>
                  <ion-radio class="radio-button-no discharged-ama" value="0"> </ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-item>
            <ion-list class="discharge-dates" inset lines="none">
              <div class="patient-discharge-dates-container">
                <ion-item class="discharge-admit-date">
                  <ion-label color="medium" position="stacked">Admitted <span style="color:#f1624a">*</span></ion-label>
                  <ion-input
                    type="date"
                    formControlName="patientAdmitDate"
                    (ionChange)="updateDischargeFields()"
                  ></ion-input>
                </ion-item>
                <ion-item class="discharge-discharge-date">
                  <ion-label color="medium" position="stacked"
                    >Discharged <span style="color:#f1624a">*</span></ion-label
                  >
                  <ion-input
                    type="date"
                    formControlName="patientDischargeDate"
                    (ionChange)="updateDischargeFields()"
                  ></ion-input>
                </ion-item>
                <img class="equal-sign" src="/assets/icon-equal-sign@2x.png" />
                <ion-item class="discharge-total-days">
                  <ion-label color="medium" position="stacked">Total Days</ion-label>
                  <ion-input formControlName="patientTotalDays" [readonly]="true"></ion-input>
                </ion-item>
              </div>
              <ion-item class="discharge-to-location" *ngIf="dischargeLabels">
                <ion-label color="medium" position="stacked"
                  >Discharged To: <span style="color:#f1624a">*</span></ion-label
                >
                <ion-radio-group formControlName="patientDischargedTo">
                  <ion-list inset lines="none">
                    <ion-item class="discharge-location" *ngFor="let dischargeLabel of dischargeLabels">
                      <ion-radio class="radio-button" mode="ios" value="{{ dischargeLabel.patientDischargeLabelId }}">
                      </ion-radio>
                      <ion-label>
                        {{ dischargeLabel.patientDischargeLabel }}
                      </ion-label>
                    </ion-item>
                  </ion-list>
                </ion-radio-group>
              </ion-item>
            </ion-list>
          </fieldset>
          <div class="section-header discharge">
            <i class="icon-medical"></i>
            <span class="contact-header">Medical Condition</span>
          </div>
          <fieldset class="patient-medical-conditions">
            <ion-list class="patient-medical-conditions" lines="none" inset>
              <ion-item>
                <ion-list inset lines="none" class="checkboxes-inline" formGroupName="patientMedicalConditions">
                  <ion-item>
                    <ion-label color="medium" position="stacked">Cardiac</ion-label>
                    <ion-checkbox formControlName="cardiacBoolean"></ion-checkbox>
                  </ion-item>
                  <ion-item>
                    <ion-label color="medium" position="stacked">Sepsis</ion-label>
                    <ion-checkbox formControlName="sepsisBoolean"></ion-checkbox>
                  </ion-item>
                  <ion-item>
                    <ion-label color="medium" position="stacked">Pulmonary</ion-label>
                    <ion-checkbox formControlName="pulmonaryBoolean"></ion-checkbox>
                  </ion-item>
                  <ion-item>
                    <ion-label color="medium" position="stacked">Other</ion-label>
                    <ion-checkbox formControlName="otherBoolean"></ion-checkbox>
                  </ion-item>
                </ion-list>
              </ion-item>
              <ion-item class="textarea-item">
                <ion-label color="medium" position="stacked"
                  >Primary Diagnosis <span style="color:#f1624a">*</span>
                </ion-label>
                <ion-textarea [autoGrow]="true" formControlName="patientPrimaryDiagnosis"></ion-textarea>
              </ion-item>
              <ion-item class="textarea-item" *ngIf="patient">
                <ion-label color="medium" position="stacked">Discharged Condition </ion-label>
                <ion-textarea [autoGrow]="true" formControlName="patientDischargedCondition"></ion-textarea>
              </ion-item>
            </ion-list>
          </fieldset>
          <fieldset class="patient-intake-questions">
            <div class="section-header">
              <i class="icon-questions"></i>
              <span class="contact-header">Intake Questions</span>
            </div>
            <ion-list lines="none" class="patient-intake-questions" inset formArrayName="patientIntakeQuestionAnswers">
              <ion-item
                *ngFor="let patientIntakeQuestion of patientIntakeQuestions; let idx = index"
                [formGroupName]="idx"
              >
                <div class="call-question-body-container">
                  <div class="call-question">
                    <ng-container *ngIf="patientIntakeQuestion.patientQuestionTypeLabel == 'rating'">
                      <ion-label color="medium">{{ patientIntakeQuestion.patientIntakeQuestion }}</ion-label>
                      <ion-list class="rating" lines="none">
                        <ion-radio-group
                          formControlName="{{ patientIntakeQuestion.patientIntakeQuestionId }}"
                          [value]="1"
                        >
                          <ion-item *ngFor="let rating of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
                            <ion-radio class="rating-option rating-button-{{ rating }}" value="{{ rating }}">
                            </ion-radio>
                          </ion-item>
                        </ion-radio-group>
                      </ion-list>
                    </ng-container>
                    <ng-container *ngIf="patientIntakeQuestion.patientQuestionTypeLabel == 'boolean'">
                      <ion-label color="medium">{{ patientIntakeQuestion.patientIntakeQuestion }}</ion-label>
                      <ion-list lines="none">
                        <ion-radio-group formControlName="{{ patientIntakeQuestion.patientIntakeQuestionId }}">
                          <ion-item class="boolean">
                            <ion-radio class="intake-boolean-radio radio-button-yes" value="1"></ion-radio>
                          </ion-item>
                          <ion-item class="boolean">
                            <ion-radio value="0" class="intake-boolean-radio radio-button-no"></ion-radio>
                          </ion-item>
                        </ion-radio-group>
                      </ion-list>
                    </ng-container>
                  </div>
                </div>
              </ion-item>
            </ion-list>
          </fieldset>
          <fieldset class="need-to-know">
            <div class="section-header">
              <i class="icon-info"></i>
              <span class="contact-header">Need To Know</span>
            </div>
            <ion-textarea [autoGrow]="true" formControlName="patientNeedToKnow"></ion-textarea>
          </fieldset>
          <ion-item class="is-active ion-float-right">
            <ion-checkbox formControlName="patientActive"></ion-checkbox>
            <ion-label color="medium">Active?</ion-label>
          </ion-item>
        </ion-list>
        <div class="form-button-controls">
          <button class="primary button" (click)="onFormSubmit()">
            Save
          </button>
          <button *ngIf="mode.edit" class="secondary button" (click)="cancel()">
            Cancel
          </button>
        </div>
      </form>
    </ion-col>
    <ion-col class="avatar-col">
      <div class="avatar-container">
        <app-patient-avatar-upload *ngIf="patient" [avatarExists]="avatarExists" [patient]="patient">
        </app-patient-avatar-upload>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
