<div class="toolbar-profile-nav">
  <div class="user-avatar">
    <div class="avatar-image">
      <a routerLink="/user/profile" class="user-profile-jump">
        <div
          class="user-avatar-image"
          *ngIf="avatarExists; else defaultAvatar"
          [style.background-image]="avatarUrl"
        ></div>
        <ng-template #defaultAvatar>
          <img class="profile-default-avatar" src="/assets/profile-default@2x.png" />
        </ng-template>
      </a>
    </div>
  </div>
  <div class="username">
    <a routerLink="/user/profile" class="user-profile-jump">
      <span *ngIf="user.userFirstName && user.userLastName">
        {{ user.userFirstName }} {{ user.userLastName.charAt(0) }}.
      </span>
    </a>
  </div>
  <i class="dropdown-trigger" (mouseenter)="toggleDropdown()" (click)="toggleDropdown()"></i>
</div>
