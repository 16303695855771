import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { AuthenticationService, HttpService } from '@app/core';
import { share, catchError } from 'rxjs/operators';
import { User } from '@app/modules/user/user';
import { Observable, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserResolver implements Resolve<User> {
  user: User;
  user$: Observable<User>;
  constructor(private authService: AuthenticationService, private http: HttpService) {}
  resolve(): Observable<User> {
    if (!this.authService.currentUserValue) {
      window.location.href = '/login';
    }
    /**
     * Make sure timeout hasn't occurred;
     */
    this.user$ = of(this.authService.currentUserValue);
    this.user = this.authService.currentUserSubject.getValue();
    var date = new Date();
    var currentTime = date.getTime();
    if (currentTime > this.user.userLoginExpires) {
      this.authService.signOut(this.user.userId);
      return;
    }
    /**
     * If we are under 15 mins, give the user another 15.
     */
    if (this.user.userLoginExpires - currentTime < 900000) {
      this.user.userLoginExpires = currentTime + 900000;
      this.authService.currentUserSubject.next(this.user);
      localStorage.setItem('followup-user', JSON.stringify(this.user));
    }
    return this.user$;
  }

  getUserByUserId(userId: number): Observable<User> {
    return this.http.get<User>('users/' + userId).pipe(
      share(),
      catchError(error => this.handleAsyncError(error))
    );
  }

  private handleAsyncError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError({
      message: 'We had trouble within the authentication service.'
    });
  }
}
