<ng-container class-="patient-summary-widget">
  <ion-row class="patient-summary">
    <div class="avatar-container">
      <div class="spanish-flag" *ngIf="patient.patientFluentLanguage == 'Spanish'">
        <div class="hola-bg"></div>
      </div>
      <app-patient-avatar [patient]="patient" [routerLink]="'/patient/edit/' + patient.patientId"> </app-patient-avatar>
    </div>
    <div class="patient-summary-container">
      <div class="patient-info">
        <div class="patient-meta-info">
          <span class="patient-medical-record-number"> #{{ patient.patientMedicalRecordNumber }} </span>
          <span class="patient-edit-icon" [routerLink]="'/patient/edit/' + patient.patientId"
            ><i class="icon-edit"></i>Edit</span
          >
          <span class="patient-operation-name">
            {{ patient.patientOperationName }}
          </span>
        </div>
        <div class="patient-name">
          <h2 class="patient-name">
            {{ patient.patientFirstName }}
            {{ patient.patientMiddleName }}
            {{ patient.patientLastName }}
          </h2>
        </div>
        <span class="patient-age"
          >Age: {{ patient.patientAge }}&nbsp;&nbsp;&nbsp;
          <span *ngIf="patient.patientGender == 'male' || patient.patientGender == 'female'"
            >{{ patient.patientGender == 'male' ? 'Male' : 'Female' }}&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span
          class="patient-contact-number"
          *ngIf="patient.patientCountryCode && patient.patientAreaCode && patient.patientPhoneNumber"
        >
          {{ patient.patientCountryCode }}-{{ patient.patientAreaCode }}-{{ patient.patientPhoneNumber }}
        </span>

        <ng-container *ngIf="patient.patientHIPAA">
          <i class="icon-hipaa patient"></i>
        </ng-container>
        <div class="patient-contact-container">
          <ion-list lines="none" inset class="alternate-numbers-list">
            <div *ngFor="let patientContact of patientContacts; let idx = index">
              <!-- Skip the first contact number, which is already listed above ^ -->
              <ion-item class="alternate-number">
                <ng-container *ngIf="patientContact.patientContactResponsiblePartyBoolean">
                  <i class="icon-hipaa" *ngIf="patientContact.patientContactResponsiblePartyBoolean"></i>
                </ng-container>
                <span class="alternate-contact" [class.first]="patientContact.patientContactResponsiblePartyBoolean">
                  <span *ngIf="patientContact.patientContactFirstName"
                    >{{ patientContact.patientContactFirstName
                    }}{{
                      patientContact.patientContactLastName ? ' ' + patientContact.patientContactLastName : ''
                    }}</span
                  ><span *ngIf="patientContact.patientContactRelationship"
                    >, {{ patientContact.patientContactRelationship }}</span
                  ><span
                    *ngIf="patientContact.patientContactAreaCode && patientContact.patientContactPhoneNumber.length > 7"
                    >: {{ patientContact.patientContactAreaCode }}-{{ patientContact.patientContactPhoneNumber }}
                  </span>
                </span>
              </ion-item>
            </div>
          </ion-list>
        </div>
      </div>
    </div>
  </ion-row>
</ng-container>
