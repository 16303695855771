<div class="sidebar" lines="none" inset *ngIf="activeOperationId">
  <form>
    <ion-item class="headline-item">
      <h3 class="headline"><i class="icon-team"></i> Team Members</h3>
    </ion-item>
    <fieldset class="operation-team-members">
      <span class="section-label" (click)="toggleOperationManagersAssignedMenu()">
        <span *ngIf="mode.edit || mode.add">Assigned </span>Manager(s)
        <i *ngIf="mode.view" class="icon-dropdown" [@turnArrow]="managerSidebarDropdownOpen ? 'open' : 'closed'"></i
      ></span>
      <ion-list
        class="assigned-manager-list"
        [class.edit]="mode.edit || mode.add"
        inset
        lines="none"
        [class.show]="managerSidebarDropdownOpen"
      >
        <div class="manager-container" *ngFor="let manager of operationManagers; let idx = index">
          <ion-item class="sidebar-listing" id="operation-manager">
            <ion-select
              *ngIf="(mode.edit || mode.add) && operationManagers; else managerSpan"
              [class.edit]="mode.edit"
              (ionChange)="managerOnSelect($event, idx)"
              placeholder="Assign Manager"
            >
              <ion-select-option
                *ngFor="let user of users"
                [value]="user.userId"
                [selected]="user.userId == manager?.userId"
              >
                {{ user.userLastName }}, {{ user.userFirstName }}
              </ion-select-option>
            </ion-select>

            <ion-note
              *ngIf="mode.edit"
              item-end
              class="remove-icon"
              (click)="removeCallRepOrManager('manager', idx, manager.userId)"
              >X</ion-note
            >
            <ng-template #managerSpan>
              <span class="operation-manager">
                <b>{{ manager?.userFirstName }} {{ manager?.userLastName }}</b>
              </span>
            </ng-template>
          </ion-item>
        </div>
        <div class="controls">
          <a *ngIf="mode.add || mode.edit" class="add-members" (click)="addAdditionalOperationManager()">+ Manager</a>
        </div>
      </ion-list>
    </fieldset>
    <fieldset class="operation-callreps">
      <ng-container>
        <span class="section-label" (click)="toggleOperationCallRepsAssignedMenu()">
          <span *ngIf="mode.edit || mode.add">Assigned </span>Care Reps
          <i *ngIf="mode.view" class="icon-dropdown" [@turnArrow]="callRepSidebarDropdownOpen ? 'open' : 'closed'"></i
        ></span>
        <ion-list
          class="assigned-callrep-list"
          lines="none"
          *ngIf="operationAssignedUsers"
          [class.show]="callRepSidebarDropdownOpen"
          [class.edit]="mode.edit || mode.add"
        >
          <div class="callrep-container" *ngFor="let operationCallRep of operationAssignedUsers; let idx = index">
            <ion-item class="sidebar-listing" id="operationCallRep-{{ idx }}">
              <ion-select
                *ngIf="users && ((mode.edit && operationAssignedUsers.length) || mode.add); else memberSpan"
                (ionChange)="callRepOnSelect($event, idx)"
                [class.edit]="mode.edit"
                placeholder="Select User"
              >
                <ion-select-option
                  *ngFor="let user of users"
                  [value]="user.userId"
                  [selected]="user.userId == operationCallRep.userId"
                >
                  {{ user.userLastName }}, {{ user.userFirstName }}
                </ion-select-option>
              </ion-select>
              <ion-note
                *ngIf="mode.edit"
                item-end
                class="remove-icon"
                (click)="removeCallRepOrManager('callrep', idx, operationCallRep.userId)"
                >X</ion-note
              >
              <ng-template #memberSpan>
                <span class="operation-callrep">
                  <b>{{ operationCallRep.userFirstName }} {{ operationCallRep.userLastName }}</b>
                </span>
              </ng-template>
            </ion-item>
          </div>
          <div class="controls">
            <a *ngIf="mode.add || mode.edit" class="add-members" (click)="addAdditionalOperationCallRep()"
              >+ Care Rep</a
            >
          </div>
        </ion-list>
      </ng-container>
    </fieldset>
  </form>
</div>
