<div class="notification-listing-sidebar" *ngIf="todaysDateDay">
  <div class="notification-listing-sidebar-calendar">
    <h4 class="todays-date">TODAY'S DATE</h4>
    <div class="caption">
      {{ todaysDateDay }}
    </div>
  </div>
  <div class="facility-dropdown-container">
    <a class="facility-dropdown-target" [routerLink]="'/notifications'">NOTIFICATIONS</a>
    <ng-container *ngIf="operationGroups">
      <div class="operation-group-container" *ngFor="let operationGroup of operationGroups; let i = index">
        <span class="operation-group" (click)="toggleOperationSidebarMenu(operationGroup)"
          >{{
            operationGroup.operationGroupShortName?.length
              ? operationGroup.operationGroupShortName
              : operationGroup.operationGroupName
          }}
          <i
            class="expand-facilities-trigger"
            [@turnArrow]="
              operationGroup.sidebarDropdownOpen ||
              operationGroup.operationGroupId == selected.operation?.operationGroupId
                ? 'open'
                : 'closed'
            "
            class="expand-facilities-trigger active"
          >
          </i>
          <ul
            class="group-operations"
            [class.show]="
              operationGroup.sidebarDropdownOpen ||
              operationGroup.operationGroupId == selected.operation?.operationGroupId
            "
            *ngIf="operationGroup.operations"
          >
            <li *ngFor="let operation of operationGroup.operations" [class.spanish]="operation.spanishSpeaking">
              <div class="operation-link-container" *ngIf="operation.operationActive">
                <a
                  class="operation-link"
                  [routerLink]="'/operations/' + operation.operationId + '/notifications'"
                  (click)="setActiveOperation(operation)"
                  [class.active]="activeOperationId == operation.operationId"
                >
                  {{ operation.operationName | slice: 0:15
                  }}<span *ngIf="operation.operationName.length > 16">
                    <!-- ... -->
                  </span>
                </a>

                <span class="current-patients">
                  {{ operation.currentAssignedPatientCount }}
                </span>
              </div>
            </li>
          </ul>
        </span>
      </div>
    </ng-container>
    <ng-template #noOperations>
      You haven't been assigned any operations yet!
    </ng-template>
  </div>
</div>
