import { Injectable } from '@angular/core';
import { AuthenticationService, HttpService } from '@app/core';
import { share, catchError } from 'rxjs/operators';
import { User } from '@app/modules/user/user';
import { Observable, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class DataService {
  constructor(private http: HttpService) {}

  public getData(): Observable<Blob> {
    return this.http
      .get<Blob>('data', { responseType: 'blob' as 'json' })
      .pipe(
        share(),
        catchError(error => this.handleAsyncError(error))
      );
  }
  private handleAsyncError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError({
      message: 'We had trouble within the authentication service.'
    });
  }
}
