import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ToolbarLogoModule } from '@app/shell/toolbar-logo/toolbar-logo.module';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    IonicModule,
    LoginRoutingModule,
    ToolbarLogoModule
  ],
  entryComponents: [LoginComponent],
  declarations: [LoginComponent]
})
export class LoginModule {}
