<ion-content>
  <ion-row>
    <ion-col class="operation-sidebar">
      <app-operation-admin-sidebar (operationGroupChangeEventHandler)="operationGroupChangeEventHandler($event)">
      </app-operation-admin-sidebar>
    </ion-col>
    <ion-col class="operation-content-wrapper" *ngIf="selected.operationGroup">
      <ion-title>Operations</ion-title>
      <app-operation-operation-listing [operationGroup]="selected.operationGroup"> </app-operation-operation-listing>
    </ion-col>
  </ion-row>
</ion-content>
