<ion-content>
  <ion-row>
    <ion-col class="notification-sidebar">
      <app-notification-listing-sidebar (operationChangeEvent)="operationChangeEventHandler($event)">
      </app-notification-listing-sidebar>
    </ion-col>
    <ion-col class="notification-content-wrapper" *ngIf="patient">
      <ion-title class="ion-float-left">
        {{ notification.notificationTypeLabel }}
      </ion-title>

      <div class="notification-listing-avatar">
        <app-patient-avatar [patient]="patient"></app-patient-avatar>
      </div>

      <div class="notification-info-container">
        <div class="patient-info">
          <div class="patient-meta-info">
            <span class="patient-medical-record-number"> #{{ patient.patientMedicalRecordNumber }} </span>
            <span class="patient-operation-name">
              {{ patient.patientOperationName }}
            </span>
          </div>
          <div
            class="patient-name"
            [routerLink]="'/call-queue/operations/' + patient.patientOperationId + '/patient/' + patient.patientId"
          >
            <h2>
              {{ patient.patientFirstName }}
              {{ patient.patientMiddleName }}
              {{ patient.patientLastName }}
            </h2>
          </div>
        </div>
        <div class="notification-information">
          <div class="notification-item">
            <span class="label">Notification Date:&nbsp;</span>
            {{ notification.notificationCreatedTime | date: 'MM/dd/yyyy':'UTC' }}
          </div>
          <div class="notification-item">
            <span class="label">Care Rep:&nbsp;</span> {{ notification.notificationUserFirstName }}
            {{ notification.notificationUserLastName }}
          </div>
          <div class="notification-item notification-container">
            <span class="notification-label">Notification</span>
            <div class="notification-message" [innerHTML]="notification.notificationMessage"></div>
          </div>
          <div class="resolution-item resolution-container" *ngIf="notification.notificationResolved">
            <span class="resolution-label">Resolution</span>
            <div class="resolution-message"></div>
            <span class="notification-date"><strong>Date: test</strong>xx/xx/xx</span>
            <span class="notification-care-rep"></span>
          </div>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
