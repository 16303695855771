<div class="operation-sidebar" *ngIf="user">
  <div class="sidebar-calendar">
    <h4 class="todays-date">TODAY'S DATE</h4>
    <div class="caption">
      {{ todaysDateDay }}
    </div>
  </div>
  <div class="facility-dropdown-container">
    <a class="facility-dropdown-target">FACILITIES</a>
    <ng-container *ngIf="operationGroups">
      <div class="operation-group-container" *ngFor="let operationGroup of operationGroups; let i = index">
        <span class="operation-group"
          ><a
            class="operation-group-link"
            (click)="setActiveOperationGroup(operationGroup)"
            [routerLink]="'/operations/group/' + operationGroup.operationGroupId"
            >{{
              operationGroup.operationGroupShortName?.length
                ? operationGroup.operationGroupShortName
                : operationGroup.operationGroupName
            }}</a
          >
          <i
            class="expand-facilities-trigger"
            [@turnArrow]="
              operationGroup.sidebarDropdownOpen ||
              (operationGroup.operationGroupId == selected.operation?.operationGroupId && !isTouched)
                ? 'open'
                : 'closed'
            "
            class="expand-facilities-trigger"
            (click)="toggleOperationSidebarMenu(operationGroup)"
          >
          </i>
          <ul
            class="group-operations"
            [class.show]="
              operationGroup.sidebarDropdownOpen ||
              (operationGroup.operationGroupId == selected.operation?.operationGroupId && !isTouched)
            "
            *ngIf="operationGroup.operations"
          >
            <li *ngFor="let operation of operationGroup.operations" [class.spanish]="operation.spanishSpeaking">
              <div class="operation-link-container" *ngIf="operation.operationActive">
                <a
                  class="operation-link"
                  [routerLink]="'/operation/' + operation.operationId"
                  (click)="setActiveOperation(operation)"
                  [class.active]="activeOperationId == operation.operationId"
                >
                  {{ operation.operationName | slice: 0:15
                  }}<span *ngIf="operation.operationName.length > 16">
                    <!-- ... -->
                  </span>
                </a>

                <span class="current-patients">
                  {{ operation.currentAssignedPatientCount }}
                </span>
              </div>
            </li>
          </ul>
        </span>
      </div>
    </ng-container>
  </div>
</div>
