<form [formGroup]="patientCallQuestionsAnswersForm">
  <ion-list scrollY="true" lines="none" class="patient-call-question-list" formArrayName="patientCallQuestionsAnswers">
    <div *ngFor="let question of questions; let idx = index" formGroupName="{{ idx }}">
      <ion-item *ngIf="question.patientQuestionTypeLabel == 'boolean'" class="call-question ion-text-center">
        <div class="call-question-body-container">
          <div class="call-question">
            <ng-container>
              <span class="patient-call-question-label">
                {{ question.patientCallQuestion }}
              </span>
              <ion-radio-group formControlName="{{ question.patientCallQuestionId }}">
                <ion-item class="boolean-question">
                  <ion-radio class="radio-button" mode="ios" value="true"> </ion-radio>
                  <ion-label>
                    Yes
                  </ion-label>
                </ion-item>
                <ion-item class="boolean-question">
                  <ion-radio class="radio-button" mode="ios" value="false"> </ion-radio>
                  <ion-label>
                    No
                  </ion-label>
                </ion-item>
              </ion-radio-group>
            </ng-container>
          </div>
        </div>
      </ion-item>
      <ng-container *ngIf="question.patientQuestionTypeLabel == 'textarea'">
        <div class="custom-question-container">
          <ion-label class="patient-call-question-label">
            {{ question.patientCallQuestion }}
          </ion-label>
          <ion-item class="patient-call-question-container call-question">
            <ion-textarea formControlName="{{ question.patientCallQuestionId }}"></ion-textarea>
          </ion-item>
        </div>
      </ng-container>
    </div>
  </ion-list>
</form>
