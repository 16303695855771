<ion-content>
  <ion-row>
    <ion-col class="notification-sidebar">
      <app-notification-listing-sidebar (operationChangeEvent)="operationChangeEventHandler($event)">
      </app-notification-listing-sidebar>
    </ion-col>
    <ion-col class="notification-content-wrapper">
      <ion-grid>
        <ion-row>
          <ion-title *ngIf="selected.operation"> Notifications - {{ selected.operation.operationName }} </ion-title>
        </ion-row>
        <ion-row class="notification-listing-row">
          <ion-col class="notification-listing-wrapper">
            <app-notification-patient-listing *ngIf="selected.operation" [operation]="selected.operation">
            </app-notification-patient-listing>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-content>
