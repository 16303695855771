<ng-container *ngIf="patientActivity">
  <ion-list class="patient-call-history-list" inset lines="none">
    <div class="patient-activity" *ngFor="let activity of patientActivity; let i = index">
      <ng-container *ngIf="activity.notificationId; else patientCall">
        <div class="activity-headline-container">
          <i class="notification-counter"></i>
          <span class="activity-headline">
            <i class="icon-notification-resolved" *ngIf="activity.notificationResolved"></i>
            <i class="icon-notication-counter" *ngIf="!activity.notificationResolved"></i>
            <span class="activity-bold" *ngIf="activity.notificationResolved">NOTIFICATION RESOLVED</span>
            <span class="activity-bold" *ngIf="!activity.notificationResolved">NOTIFICATION</span> &mdash;
            <span class="activity-label">{{ activity.notificationTypeLabel }}</span>
          </span>
          <div class="activity-detail-container">
            <span class="activity-by">
              {{ activity.notificationUserFirstName }} {{ activity.notificationUserLastName }}
            </span>
            <span class="activity-date"> - {{ activity.notificationCreatedTime | date: 'MM/dd/yyyy':'UTC' }}</span>
          </div>
        </div>

        <div class="notification-message-container">
          <span class="notification-label">
            Message
          </span>
          <div class="notification-message" [innerHTML]="activity.notificationMessage"></div>
        </div>
      </ng-container>
      <ng-template #patientCall>
        <div class="patient-call">
          <!-- This logic assumes that the patientCallCounts come to us in the right order -->
          <div id="{{ 'call-' + activity.patientCallCount }}" class="activity-headline-container">
            <img
              class="graduated-banner"
              *ngIf="patient.patientStatus != 'In Progress' && patientFirstCallIndexCallId == activity.patientCallId"
              src="/assets/patient-graduated-banner@2x.png"
            />
            <i
              *ngIf="!(patientFirstCallIndexCallId == activity.patientCallId && patient.patientStatus != 'In Progress')"
              class="icon-phone-icon"
              style="background-image: url('{{ activity.patientCallStatusIconImage }}')"
            ></i>
            <span class="activity-headline">
              <span class="activity-bold">Call {{ activity.patientCallCount }}</span>
              &mdash;
              <span
                class="activity-label"
                *ngIf="
                  !(patientFirstCallIndexCallId == activity.patientCallId && patient.patientStatus != 'In Progress')
                "
              >
                {{ activity.patientCallStatusLabel }}
              </span>
              <span
                class="activity-label"
                *ngIf="patient.patientStatus != 'In Progress' && patientFirstCallIndexCallId == activity.patientCallId"
              >
                {{ patient.patientStatus }}
              </span>
            </span>
            <div class="activity-detail-container">
              <span class="activity-by">
                {{ activity.patientCalledByUserFirstName }} {{ activity.patientCalledByUserLastName }}
              </span>
              <span class="activity-date"> - {{ activity.patientCallEndTime | date: 'MM/dd/yyyy':'UTC' }}</span>
            </div>
          </div>
          <div class="call-question-container" *ngIf="activity.patientCallStatusLabel == 'Contacted'">
            <div *ngFor="let patientCallQuestion of activity.patientCallQuestions; let idx = index" lines="none" inset>
              <ion-item class="ion-float-left">
                <span
                  class="call-question-label"
                  [class.is-highlighted]="patientCallQuestion.patientCallQuestionIsHighlighted"
                >
                  {{ patientCallQuestion.patientCallQuestion }}
                </span>
                <span class="call-question-answer-label">
                  <ng-container *ngIf="patientCallQuestion.patientCallQuestionAnswer; else noAnswer">
                    <ng-container *ngIf="patientCallQuestion.patientQuestionTypeLabel == 'boolean'; else nonBoolean">
                      <span class="call-question-answer"
                        >&nbsp;
                        {{ patientCallQuestion.patientCallQuestionAnswer === 'true' ? 'Yes' : 'No' }}
                      </span>
                    </ng-container>
                    <ng-template #nonBoolean>
                      <span class="call-question-answer">
                        &nbsp;
                        {{ patientCallQuestion.patientCallQuestionAnswer }}
                      </span>
                    </ng-template>
                  </ng-container>
                  <ng-template #noAnswer>
                    &nbsp;N/A
                  </ng-template>
                </span>
              </ion-item>
            </div>
          </div>

          <div class="patient-call-notes-container">
            <span class="call-notes-label" [class.is-highlighted]="activity.patientCallNotesHighlighted">
              Call Notes
            </span>
            <div
              class="patient-call-notes"
              style="white-space: pre-wrap;"
              *ngIf="activity.patientCallNotes; else noCallNotes"
              [innerHTML]="activity.patientCallNotes"
            ></div>
          </div>
        </div>
      </ng-template>
    </div>
  </ion-list>
  <ng-template #noActivity>
    No patient activity yet!
  </ng-template>
</ng-container>
