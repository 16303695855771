<div class="patient-avatar-container">
  <div
    class="patient-avatar-image"
    [style.background-image]="avatarUrl"
    [class.is-circle]="isCircle"
    *ngIf="avatarUrl; else defaultAvatar"
  ></div>
  <ng-template #defaultAvatar>
    <div
      class="default patient-avatar-image"
      [class.is-circle]="isCircle"
      style="background-image:url('/assets/photo-mask@2x.png');"
    ></div>
  </ng-template>
</div>
