<form [formGroup]="patientNextCallQuestionsForm">
  <i class="icon-question"></i>
  <h3>
    Next Call
  </h3>
  <ion-list lines="none" formArrayName="patientCallQuestions">
    <ion-item
      class="next-call-question-textarea"
      *ngFor="let patientCallQuestion of patientCallQuestions; let idx = index"
    >
      <ion-textarea
        rows="1"
        [class.is-highlighted]="patientCallQuestion.patientCallQuestionIsHighlighted"
        formControlName="{{ idx }}"
        placeholder="Question {{ idx + 1 }}"
      ></ion-textarea>
    </ion-item>
    <ion-item class="add-next-question" (click)="addNextCallQuestion()">+ Question</ion-item>
  </ion-list>
</form>
