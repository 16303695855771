<ng-container>
  <form id="patientCallNotesForm" [formGroup]="patientCallNotesForm">
    <ion-list class="call-notes-list" lines="none" inset>
      <ion-item class="patient-call-notes" *ngIf="patientCall">
        <ion-label
          position="stacked"
          *ngIf="
            patientCall.patientCallStatusLabel == 'Started' ||
            patientCall.patientCallStatusLabel == 'User Selected Status'
          "
        >
          Call Notes
        </ion-label>
        <ion-label position="stacked" *ngIf="patientCall.patientCallStatusLabel == 'In Review'">
          Edit &amp; Review Call
        </ion-label>
        <ion-textarea
          *ngIf="patientCallNotesForm.get('patientCallNotes')"
          [class.is-highlighted]="highlighterActive"
          [autoGrow]="true"
          formControlName="patientCallNotes"
        ></ion-textarea>
      </ion-item>
      <ion-item class="patient-call-note-highlight-controls">
        <a (click)="highlightPatientCallNotes()">
          <img src="/assets/icon-highlight-flag@2x.png" />
          Highlight
        </a>
      </ion-item>
    </ion-list>
  </form>
</ng-container>
