<div class="team-sidebar">
  <div class="team-sidebar-calendar">
    <h4 class="todays-date">TODAY'S DATE</h4>
    <div class="caption">
      {{ todaysDateDay }}
    </div>
  </div>

  <div class="team-dropdown-container" *ngIf="teams">
    <a class="team-dropdown-target" [routerLink]="'/teams'">THE TEAM<span *ngIf="teams.length > 1">s</span></a>
    <div *ngFor="let team of teams">
      <div class="team-member-container">
        <span class="sidebar-label" (click)="toggleTeamManagerSidebarMenu(team)"
          >MANAGERS
          <i class="expand-members-trigger" [@turnArrow]="team.teamManagerSidebarDropdownOpen ? 'open' : 'closed'"> </i>
        </span>
        <ul class="team-members-list" *ngIf="team.teamManagerSidebarDropdownOpen">
          <li *ngFor="let teamMember of team.teamManagers">
            <a [routerLink]="'/teams/' + team.teamId + '/members/' + teamMember.teamMemberId" class="team-member-link">
              {{ teamMember.teamMemberFirstName }} {{ teamMember.teamMemberLastName }}
            </a>
          </li>
        </ul>
      </div>
      <div class="team-member-container">
        <span class="sidebar-label" (click)="toggleTeamCareRepSidebarMenu(team)"
          >CARE REPS
          <i class="expand-members-trigger" [@turnArrow]="team.teamCareRepSidebarDropdownOpen ? 'open' : 'closed'"> </i>
        </span>
        <ul class="team-members-list" *ngIf="team.teamCareRepSidebarDropdownOpen">
          <li *ngFor="let teamMember of team.teamCareReps">
            <a [routerLink]="'/teams/' + team.teamId + '/members/' + teamMember.teamMemberId" class="team-member-link">
              {{ teamMember.teamMemberFirstName }} {{ teamMember.teamMemberLastName }}
            </a>
          </li>
        </ul>
      </div>
      <div class="team-member-container">
        <span class="sidebar-label" (click)="toggleTeamSpanishSidebarMenu(team)"
          >SPANISH
          <i class="expand-members-trigger" [@turnArrow]="team.teamSpanishSidebarDropdownOpen ? 'open' : 'closed'"> </i>
        </span>
        <ul class="team-members-list" *ngIf="team.teamSpanishSidebarDropdownOpen">
          <li *ngFor="let teamMember of team.teamSpanishSpeaking">
            <a [routerLink]="'/teams/' + team.teamId + '/members/' + teamMember.teamMemberId" class="team-member-link">
              {{ teamMember.teamMemberFirstName }} {{ teamMember.teamMemberLastName }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
