<div class="call-queue-sidebar" *ngIf="todaysDateDay">
  <div class="call-queue-sidebar-calendar">
    <h4 class="todays-date">TODAY'S DATE</h4>
    <div class="caption">
      {{ todaysDateDay }}
    </div>
  </div>
  <div class="facility-dropdown-container">
    <a class="facility-dropdown-target" (click)="toggleOperationSidebarMenu()">FACILITIES</a>
    <ng-container *ngIf="operationGroups">
      <div class="operation-group-container" *ngFor="let operationGroup of operationGroups; let i = index">
        <div *ngIf="operationGroup.operations?.length">
          <span class="operation-group" (click)="toggleOperationSidebarMenu(operationGroup)"
            >{{
              operationGroup.operationGroupShortName?.length
                ? operationGroup.operationGroupShortName
                : operationGroup.operationGroupName
            }}
            <i
              class="expand-facilities-trigger"
              [@turnArrow]="
                operationGroup.sidebarDropdownOpen ||
                (operationGroup.operationGroupId == selected.operation?.operationGroupId && !isTouched)
                  ? 'open'
                  : 'closed'
              "
            >
            </i>
            <ul
              class="group-operations"
              [class.show]="
                operationGroup.sidebarDropdownOpen ||
                (operationGroup.operationGroupId == selected.operation?.operationGroupId && !isTouched)
              "
            >
              <li
                *ngFor="let operation of operationGroup.operations"
                [class.spanish]="operation.operationSpanishSpeaking"
              >
                <div class="operation-link-container" *ngIf="operation.operationActive">
                  <a
                    class="operation-link"
                    [routerLink]="'/call-queue/operations/' + operation.operationId"
                    (click)="setActiveOperation(operation)"
                    [class.active]="activeOperationId == operation.operationId"
                  >
                    {{ operation.operationName | slice: 0:15
                    }}<span *ngIf="operation.operationName.length > 16">
                      <!-- ... -->
                    </span>
                  </a>

                  <span class="current-patients">
                    {{ operation.currentAssignedPatientCount }}
                  </span>
                  <span class="discharge-indicator" *ngIf="operation.currentNewDischargeCount">
                    <i class="indicator"></i>
                  </span>
                </div>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </ng-container>

    <span class="spanish-indicator">
      <a href="/call-queue/spanish">SPANISH</a> <i class="patient"></i
      ><i *ngIf="spanishNewDischarges" class="indicator"></i
    ></span>

    <ng-template #noOperations>
      You haven't been assigned any operations yet!
    </ng-template>
  </div>
</div>
