<div class="listing-controls">
  <app-listing-filter
    [options]="colDefs"
    (sortOptionSelectedEventEmitter)="sortOptionSelected($event)"
    (sortDirectionSelectedEventEmitter)="toggleAscDesc($event)"
  >
  </app-listing-filter>
  <app-listing-search (searchFilterEventEmitted)="searchNotifications($event)"></app-listing-search>
</div>
<table class="notification-table">
  <thead>
    <tr>
      <th>
        Date
      </th>
      <th>
        Patient
      </th>
      <th>
        Type
      </th>
      <th>
        Care Rep
      </th>
      <th>
        Status
      </th>
    </tr>
  </thead>
  <tbody *ngIf="notificationsFiltered; else noNotifications">
    <tr *ngFor="let notification of notificationsFiltered" class="active">
      <td>
        {{ notification.notificationCreatedTime | date: 'MM/dd/yy':'UTC' }}
      </td>
      <td class="has-link" [routerLink]="'/notifications/' + notification.notificationId">
        {{ notification.notificationPatientFirstName }}, {{ notification.notificationPatientLastName }}
      </td>
      <td>
        <i class="notification-icon" class="{{ notification.notificationTypeLabel }}"></i>
        <span>{{ notification.notificationTypeLabel }} </span>
      </td>
      <td>
        {{ notification.notificationCreatedByUserFirstName }}
        {{ notification.notificationCreatedByUserLastName }}
      </td>
      <td>
        <span>{{ notification.notificationResolved ? 'Resolved' : 'Unresolved' }} </span>
      </td>
    </tr>
  </tbody>
</table>
<jw-pagination *ngIf="notificationsFiltered" [items]="notificationsFiltered" (changePage)="onChangePage($event)">
</jw-pagination>

<ng-template #noNotifications>
  <tbody>
    <p style="font-family: Avenir">
      Sorry! No notifications exist for this operation, or your search is too narrow!
    </p>
  </tbody>
</ng-template>
