import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-queue-legend',
  templateUrl: './call-queue-legend.component.html',
  styleUrls: ['./call-queue-legend.component.scss']
})
export class CallQueueLegendComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
