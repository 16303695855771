<div class="patient-avatar-container">
  <div
    class="patient-avatar-image"
    *ngIf="avatarExists && !changingAvatar; else defaultAvatar"
    (click)="uploadPatientAvatarPhoto()"
    [style.background-image]="avatarUrl"
  ></div>
</div>
<ng-template #defaultAvatar>
  <img class="patient-avatar-image" (click)="uploadPatientAvatarPhoto()" src="/assets/photo-mask@2x.png" />
</ng-template>
<button class="secondary button" (click)="uploadPatientAvatarPhoto()">
  <span *ngIf="avatarExists">Change Photo</span>
  <span *ngIf="!avatarExists">Add Photo</span>
</button>
