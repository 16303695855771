<ion-content>
  <ion-row>
    <ion-col class="team-sidebar">
      <app-team-listing-sidebar (teamChangeEvent)="teamChangeEventHandler($event)"> </app-team-listing-sidebar>
    </ion-col>
    <ion-col class="team-content-wrapper" *ngIf="teams">
      <ion-grid>
        <ion-row>
          <ion-title *ngIf="selected.team">Team Members</ion-title>
        </ion-row>
        <ion-row class="team-listing-row">
          <ion-col class="team-listing-wrapper">
            <app-team-members-listing *ngIf="selected.team" [team]="selected.team"> </app-team-members-listing>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-content>
