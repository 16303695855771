<div class="user-dashboard">
  <ion-grid>
    <ion-row class="top-row">
      <ion-col class="left-box">
        <div class="message-container">
          <h1>Hello {{ user.userFirstName ? user.userFirstName : 'Friend' }}!</h1>
          <div class="team-messages" *ngIf="teamMessage">
            <div class="team-message" *ngIf="teamMessage" [innerHTML]="teamMessage.messageBody"></div>
          </div>
        </div>
      </ion-col>
      <ion-col class="post-it-box">
        <div class="posted-notes">
          <ul class="user-messages">
            <li class="user-message-note">
              <div class="user-message-body" *ngIf="userMessage">
                <p class="user-message" [innerHTML]="userMessage.messageBody"></p>
                <span class="from-sender">-{{ userMessage.messageSenderFirstName }}</span>
              </div>
            </li>
          </ul>
        </div>
      </ion-col>
      <ion-col class="right-col">
        <div class="confetti-box"></div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="patient-pipeline-col">
        <div class="patient-pipeline">
          <span class="pipeline-title">Patient Pipeline</span>
          <ul class="metric-list">
            <li class="metric">
              <span class="label-container">
                <span class="metric-label">Today's Calls</span>
                <span class="metric-description">
                  Queue
                </span>
              </span>
              <div class="progress-bar-container">
                <div class="red progress-bar" [style.width.%]="todaysCallsProgress"></div>
                <span class="start-metric">{{ todaysCalls.completed }}</span>
                <span class="finish-metric">{{ todaysCalls.scheduled }}</span>
              </div>
            </li>
            <li class="metric">
              <span class="label-container">
                <span class="metric-label">Weekly Calls</span>
                <span class="metric-description">
                  Queue
                </span>
              </span>
              <div class="progress-bar-container">
                <div class="blue progress-bar" [style.width.%]="weeklyCallsProgress"></div>
                <span class="start-metric">{{ weeklyCalls.completed }}</span>
                <span class="finish-metric">{{ weeklyCalls.scheduled }}</span>
              </div>
            </li>
            <li class="metric">
              <span class="label-container">
                <span class="metric-label">Calls Made</span>
                <span class="metric-description">
                  Total Calls Made
                </span>
              </span>
              <div class="progress-bar-container">
                <div class="green progress-bar" [style.width.%]="callsMadeProgress"></div>
                <span class="start-metric">{{ callsMade.callsMade }}</span>
                <span class="finish-metric">{{ callsMade.totalCalls }}</span>
              </div>
            </li>
            <li class="metric">
              <span class="label-container">
                <span class="metric-label">My Notifications</span>
                <span class="metric-description">
                  Total Notifications
                </span>
              </span>
              <div class="progress-bar-container">
                <div class="yellow progress-bar" [style.width.%]="notificationsProgress"></div>
                <span class="start-metric">{{ notificationsSent.user ? notificationsSent.user : 0 }}</span>
                <span class="finish-metric">{{ notificationsSent.totalNotifications }}</span>
              </div>
            </li>
          </ul>
        </div>
      </ion-col>
      <ion-col class="red-data-box" *ngIf="user.userLevel == userLevels.admin">
        <h1>Weekly Metrics</h1>
        <div *ngIf="countReady">
          <div class="percentage-number">
            <h2>{{ weeklyCallsToNotificationsPercentage ? weeklyCallsToNotificationsPercentage : '0' }}%</h2>
          </div>
          <h3 class="notifications-count-result">
            Resulted in Notifications
          </h3>
        </div>
      </ion-col>
      <ion-col class="right-col blue-data-box" *ngIf="user.userLevel == userLevels.admin">
        <h1>All Calls</h1>
        <div *ngIf="countReady">
          <h2>{{ totalCallsToNotificationsPercentage ? totalCallsToNotificationsPercentage : '0' }}%</h2>
          <h3 class="notifications-count-result">
            Resulted in Notifications
          </h3>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="everybody-is-callout">
    <img src="/assets/everybody-graphic@2x.png" alt="" />
  </div>
</div>
