<ion-content>
  <ion-row *ngIf="operation">
    <ion-col class="operation-sidebar">
      <app-operation-admin-sidebar (operationChangeEvent)="operationChangeEventHandler($event)">
      </app-operation-admin-sidebar>
    </ion-col>
    <ion-col class="operation-form-content-wrapper">
      <ion-title *ngIf="mode.add">New Operation</ion-title>
      <h1 *ngIf="mode.view || mode.edit">{{ operation.operationGroupShortName }} - {{ operation.operationName }}</h1>
      <form id="operation-form" [formGroup]="operationForm" novalidate>
        <fieldset formGroupName="operation">
          <ion-list *ngIf="mode.view" class="operation-details" lines="none" inset>
            <div class="operation-listing-header">
              <h2 class="section-header ion-float-left"><i class="icon-info"></i> Operation</h2>
              <div class="operation-controls">
                <span class="control ion-float-right">
                  <a [routerLink]="'/operation/edit/' + operation.operationId" class="edit-link">
                    <i class="icon-edit"></i>
                    Edit
                  </a>
                </span>
              </div>
            </div>
          </ion-list>
          <ion-list lines="none" class="operation-information-view" *ngIf="mode.view">
            <ion-item class="operation-name-header">
              {{ operation.operationName }}
            </ion-item>
            <ion-item class="operation-address" *ngIf="operationAddress">
              {{ operation.operationAddress }}, {{ operation.operationState }}, {{ operation.operationZip }}
            </ion-item>
            <ion-item class="operation-phone" *ngIf="operation.operationPhoneNumber">
              <a
                href="tel:{{ operation.operationCountryCode }}-{{ operation.operationAreaCode }}-{{
                  operation.operationPhoneNumber
                }}"
              >
                +{{ operation.operationCountryCode }}-{{ operation.operationAreaCode }}-{{
                  operation.operationPhoneNumber
                }}
              </a>
            </ion-item>
          </ion-list>
          <ion-list *ngIf="mode.add || mode.edit" class="operation-details" lines="none" inset>
            <div class="operation-listing-header">
              <h2 class="section-header ion-float-left"><i class="icon-info"></i> Operation</h2>
              <div class="operation-controls ion-float-right">
                <ion-radio-group class="operation-active-controls" formControlName="operationActive">
                  <ion-item class="active-control">
                    <span class="control">
                      <ion-radio class="radio-button" mode="ios" value="1"></ion-radio>
                      <span>
                        Active
                      </span>
                    </span>
                  </ion-item>
                  <ion-item class="active-control">
                    <span class="control">
                      <ion-radio class="radio-button-notify" mode="ios" value="0"></ion-radio>
                      <span>
                        Archive
                      </span>
                    </span>
                  </ion-item>
                </ion-radio-group>
              </div>
            </div>
            <ion-item class="operation-group">
              <ion-label color="medium" position="stacked">Ownership <span style="color:#f1624a">*</span></ion-label>
              <ion-select
                *ngIf="(operationGroups && mode.add) || operation.operationGroupShortName"
                formControlName="operationGroupId"
                (ionChange)="operationGroupOnSelect($event, idx)"
                class="operation-group-select"
                placeholder="Ownership"
              >
                <ion-select-option
                  *ngFor="let operationGroup of operationGroups"
                  [selected]="operationGroup.operationGroupId == operation.operationGroupId"
                  [value]="operationGroup.operationGroupId"
                  (change)="onOperationGroupChange($event)"
                >
                  {{ operationGroup.operationGroupName }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <span class="ion-float-right add-contact-label add-group-label" (click)="addOperationGroupForm()"
              >Add Group</span
            >
            <ion-modal class="modal" *ngIf="addOperationGroupModalOn">
              <form [formGroup]="addOperationGroupFormControl">
                <ion-label color="medium" position="stacked">Group Name<span style="color:#f1624a">*</span></ion-label>
                <ion-input
                  type="text"
                  placeholder="(as long as you want)"
                  inputCapitalizeFirst
                  formControlName="operationGroupName"
                ></ion-input>
                <ion-label color="medium" position="stacked">Short Name<span style="color:#f1624a">*</span></ion-label>
                <ion-input
                  type="text"
                  placeholder=" (max 9 characters)"
                  inputCapitalizeFirst
                  formControlName="operationGroupShortName"
                ></ion-input>
                <ion-buttons>
                  <button *ngIf="!mode.view" class="ion-float-right primary button" (click)="addOperationGroup()">
                    Save
                  </button>
                  <button
                    *ngIf="!mode.view"
                    class="ion-float-right secondary button"
                    (click)="closeOperationGroupForm()"
                  >
                    Cancel
                  </button>
                </ion-buttons>
              </form>
            </ion-modal>
            <ion-item class="operation-name">
              <ion-label color="medium" position="stacked">Facility <span style="color:#f1624a">*</span></ion-label>
              <ion-input type="text" formControlName="operationName" placeholder="Facility" inputCapitalizeFirst>
              </ion-input>
            </ion-item>
            <ion-item class="address">
              <ion-label color="medium" position="stacked">Address</ion-label>
              <ion-input type="text" formControlName="operationAddress" placeholder="Address"></ion-input>
            </ion-item>
            <ion-item class="city">
              <ion-label color="medium" position="stacked">City</ion-label>
              <ion-input type="text" formControlName="operationCity" placeholder="City" inputCapitalizeFirst>
              </ion-input>
            </ion-item>
            <ion-item class="state">
              <ion-label color="medium" position="stacked">State</ion-label>
              <ion-input type="text" formControlName="operationState" placeholder="State"></ion-input>
            </ion-item>
            <ion-item class="zip">
              <ion-label color="medium" position="stacked">Zip</ion-label>
              <ion-input type="text" formControlName="operationZip" placeholder="Zip"></ion-input>
            </ion-item>
            <ion-list inset lines="none" class="phone-container">
              <ion-item class="country-code">
                <ion-label color="medium" position="stacked">+1</ion-label>
                <ion-input type="text" formControlName="operationCountryCode" placeholder="+1"></ion-input>
              </ion-item>
              <ion-item class="area-code">
                <ion-label color="medium" position="stacked">(###)</ion-label>
                <ion-input type="text" maxlength="3" formControlName="operationAreaCode" placeholder="(###)">
                </ion-input>
              </ion-item>
              <ion-item class="phone-number">
                <ion-label color="medium" position="stacked">Phone #</ion-label>
                <ion-input type="text" inputAddPhoneDashes formControlName="operationPhoneNumber" placeholder="Phone #">
                </ion-input>
              </ion-item>
            </ion-list>
          </ion-list>
        </fieldset>

        <fieldset class="operation-contacts" *ngIf="operationContacts && notificationsLoaded">
          <ng-container>
            <div *ngIf="mode.view && operationContacts[0].operationContactId != null">
              <h2 class="section-header"><i class="icon-contact"></i> Contacts</h2>
            </div>
            <div *ngIf="mode.edit || mode.add">
              <h2 class="section-header"><i class="icon-contact"></i> Contacts</h2>
            </div>
            <ion-list lines="none" class="operation-contacts-view" *ngIf="mode.view">
              <div class="operation-contact-container" *ngFor="let operationContact of operationContacts">
                <ion-item class="contact-name" *ngIf="operationContact.operationContactFirstName">
                  {{ operationContact.operationContactFirstName + ' ' + operationContact.operationContactLastName
                  }}<span class="contact-title" *ngIf="operationContact.operationContactTitle"
                    >, {{ operationContact.operationContactTitle }}</span
                  >
                </ion-item>
                <ion-item class="contact-email" *ngIf="operationContact.operationContactEmail">
                  <a href="mailto:{{ operationContact.operationContactEmail }}">{{
                    operationContact.operationContactEmail
                  }}</a>
                </ion-item>
                <ion-item class="contact-phone" *ngIf="operationContact.operationPhoneNumber">
                  <a
                    href="tel:{{ operationContact.operationCountryCode }}-{{ operationContact.operationAreaCode }}-{{
                      operationContact.operationPhoneNumber.substring(0, 3) +
                        '-' +
                        operation.operationPhoneNumber.substring(3, 7)
                    }}"
                  >
                    +{{ operationContact.operationCountryCode }}-{{ operationContact.operationAreaCode }}-{{
                      operationContact.operationPhoneNumber.substring(0, 3) +
                        '-' +
                        operation.operationPhoneNumber.substring(3, 7)
                    }}
                  </a>
                </ion-item>
              </div>
            </ion-list>
            <ion-list
              *ngIf="mode.add || mode.edit"
              class="operation-contacts-list"
              formArrayName="operationContacts"
              lines="none"
              inset
            >
              <div class="operation-contact-item" *ngFor="let operationContact of operationContacts; let idx = index">
                <div class="contact-header-wrapper">
                  <span class="contact-label">Contact {{ idx + 1 }}</span>
                  <!-- only show removal option if it's past the first index -->
                  <span class="ion-float-right remove-contact-label" (click)="removeOperationContact(idx)">
                    Remove contact
                  </span>
                </div>
                <ion-list formGroupName="{{ idx }}" class="operation-contact-entry" lines="none" inset>
                  <ion-item class="first-name">
                    <ion-label position="stacked" color="medium">
                      First Name <span style="color:#f1624a">*</span>
                    </ion-label>
                    <ion-input
                      type="text"
                      formControlName="operationContactFirstName"
                      placeholder="First Name"
                      inputCapitalizeFirst
                      required
                    ></ion-input>
                  </ion-item>
                  <ion-item class="last-name">
                    <ion-label position="stacked" color="medium">
                      Last Name <span style="color:#f1624a">*</span>
                    </ion-label>
                    <ion-input
                      type="text"
                      formControlName="operationContactLastName"
                      placeholder="Last Name"
                      inputCapitalizeFirst
                      required
                    >
                    </ion-input>
                  </ion-item>
                  <ion-item class="contact-email">
                    <ion-label position="stacked" color="medium">
                      Email <span style="color:#f1624a">*</span>
                    </ion-label>
                    <ion-input type="text" formControlName="operationContactEmail" placeholder="Email"></ion-input>
                  </ion-item>

                  <ion-item class="title-position">
                    <ion-label position="stacked" color="medium">
                      Title / Position
                    </ion-label>
                    <ion-input type="text" formControlName="operationContactTitle" placeholder="Title / Position">
                    </ion-input>
                  </ion-item>
                  <div class="phone-container">
                    <ion-item class="country-code">
                      <ion-label position="stacked" color="medium">
                        +1
                      </ion-label>
                      <ion-input
                        type="text"
                        maxlength="1"
                        formControlName="operationContactCountryCode"
                        placeholder="1"
                      ></ion-input>
                    </ion-item>
                    <ion-item class="area-code">
                      <ion-label position="stacked" color="medium">
                        (###)
                      </ion-label>
                      <ion-input
                        type="text"
                        maxlength="3"
                        formControlName="operationContactAreaCode"
                        placeholder="(###)"
                      ></ion-input>
                    </ion-item>
                    <ion-item class="phone-number">
                      <ion-label position="stacked" color="medium">
                        Phone #
                      </ion-label>
                      <ion-input
                        type="text"
                        inputAddPhoneDashes
                        formControlName="operationContactPhoneNumber"
                        placeholder="Phone #"
                      >
                      </ion-input>
                    </ion-item>

                    <ion-item class="contact-order">
                      <ion-label position="stacked" color="medium">
                        Contact Order
                      </ion-label>
                      <ion-input ype="number" formControlName="operationContactOrder"></ion-input>
                    </ion-item>
                  </div>
                  <div class="section-header" *ngIf="notificationsLoaded">
                    <span class="notification-header">
                      Notifications
                    </span>
                  </div>
                  <ion-list class="notification-container" formArrayName="operationContactNotifications">
                    <ion-item
                      class="notification-type-option"
                      *ngFor="let notificationType of notificationTypes; let idx = index"
                      formGroupName="{{ idx }}"
                    >
                      <ion-checkbox formControlName="{{ notificationType.notificationTypeId }}"></ion-checkbox>
                      <ion-label color="normal">{{ notificationType.notificationTypeLabel }}</ion-label>
                    </ion-item>
                  </ion-list>
                </ion-list>
              </div>
              <span class="ion-float-right add-contact-label" (click)="addAdditionalOperationContact()"
                >Add Contact</span
              >
            </ion-list>
          </ng-container>
        </fieldset>
        <button *ngIf="!mode.view" class="ion-float-right primary button" (click)="onFormSubmit()">
          Save
        </button>
        <button *ngIf="!mode.view" class="ion-float-right secondary button" (click)="onCancelSubmit()">
          Cancel
        </button>
      </form>
    </ion-col>
    <ion-col class="right-admin-col">
      <app-operation-admin-right-sidebar
        *ngIf="operation && availableUsers"
        [operation]="operation"
        [users]="availableUsers"
        [mode]="mode"
      >
      </app-operation-admin-right-sidebar>
    </ion-col>
  </ion-row>
</ion-content>
