<div class="listing-controls">
  <app-listing-filter
    [options]="colDefs"
    (sortOptionSelectedEventEmitter)="handleSortOptionEvent($event)"
    (sortDirectionSelectedEventEmitter)="handleSortDirectionEvent($event)"
  >
  </app-listing-filter>
  <app-listing-search (searchFilterEventEmitted)="handleSearchFilterEvent($event)"> </app-listing-search>
</div>
<table class="operation-table">
  <thead>
    <tr>
      <th>
        Facility
      </th>
      <th>
        Ownership
      </th>
      <th class="centered">
        Queue
      </th>
      <th class="centered">
        Notifs
      </th>
      <th class="centered">
        Grads
      </th>
      <th>
        Status
      </th>
      <th class="centered">
        Date Added
      </th>
      <th style="text-align: center; padding-left: 0;">
        Patients
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let operation of operationsFiltered" class="active" [class.archived]="!operation.operationActive">
      <td class="has-link listing-body-cell" [routerLink]="'/operation/' + operation.operationId">
        <div class="operation-information">
          <span class="operation-name">
            {{ operation.operationName }}
          </span>
        </div>
      </td>
      <td class="listing-body-cell">
        <div class="operation-group-information">
          <span class="operation-group-name">
            {{ operation.operationGroupName }}
          </span>
        </div>
      </td>
      <td class="centered listing-body-cell">
        <div class="operation-group-information">
          <span class="operation-group-name">
            {{ operation.currentAssignedPatientCount }}
          </span>
        </div>
      </td>
      <td class="centered listing-body-cell">
        <div class="operation-group-information">
          <span class="operation-notifications">
            {{ operation.totalNotifications }}
          </span>
        </div>
      </td>
      <td class="centered listing-body-cell">
        <div class="operation-group-information">
          <span class="operation-graduates">
            {{ operation.totalGraduates }}
          </span>
        </div>
      </td>
      <td class="listing-body-cell">
        <div class="operation-group-information">
          <span class="operation-status" [class.archived]="!operation.operationActive">
            {{ operation.operationActive ? 'Active' : 'Archived' }}
          </span>
        </div>
      </td>
      <td class="centered listing-body-cell">
        <div class="operation-group-information">
          <span class="operation-start-date">
            {{ operation.operationStartDate | date: 'MM/dd/YY' }}
          </span>
        </div>
      </td>
      <td class="listing-body-cell">
        <div class="operation-group-information">
          <span class="operation-patients-link">
            <a href="/operations/{{ operation.operationId }}/patients"
              ><img src="/assets/icon-patient-nav@2x.png" alt=""
            /></a>
          </span>
        </div>
      </td>
    </tr>
  </tbody>
</table>
<jw-pagination
  *ngIf="operationsFiltered"
  [items]="operationsFiltered"
  (changePage)="onChangePage($event)"
></jw-pagination>
<ng-template #noOperations>
  Sorry! No operations exist for this group!
</ng-template>
