<div class="patient-filter">
  <ion-row>
    <ion-col col-auto>
      <table>
        <tr>
          <td>
            <ion-input placeholder="Patient Search" (ionInput)="searchPatientCallHistoryByText($event)"> </ion-input>
          </td>
          <td>
            <ion-icon name="search"></ion-icon>
          </td>
        </tr>
      </table>
    </ion-col>
  </ion-row>
  <ng-container class="patient-filter-results" *ngIf="patientCalls; else noPatientCalls">
    <ion-list *ngIf="patientCallsFiltered.length >= 1; else noCalls" lines="none" inset>
      <ion-item class="patient-call-filter-listing" *ngFor="let patientCall of patientCallsFiltered">
        <a
          class="patient-link"
          [routerLink]="
            '/call-queue/operations/' + patientCall.patientOperationId + '/patient/' + patientCall.patientId
          "
        >
          <span class="patient-filter-patient-name">
            {{ patientCall.patientFirstName }} {{ patientCall.patientLastName }}:&nbsp;
          </span>
          <span class="patient-filter-call-date"
            >Call {{ patientCall.patientCallCount }}/{{ patientCall.patientCallStatusLabel }} -
            <ng-container *ngIf="patientCall.patientCallEndTime; else callScheduledTime">
              {{ patientCall.patientCallEndTime | date: 'MM/dd/yy':'UTC' }}
            </ng-container>
            <ng-template #callScheduledTime>
              {{ patientCall.patientCallScheduledTime | date: 'MM/dd/yy':'UTC' }}
            </ng-template>
          </span>
        </a>
      </ion-item>
    </ion-list>
  </ng-container>
</div>
<ng-template #noPatientCalls>
  <span class="no-calls">
    No patient calls found for selected date.
  </span>
</ng-template>
<ng-template #noCalls>
  <span class="no-calls"> No results for search. </span>
</ng-template>
