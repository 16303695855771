<div class="listing-search">
  <ion-row>
    <ion-col col-auto>
      <table>
        <tr>
          <td>
            <ion-input placeholder="Search" (ionInput)="updateSearchText($event)"> </ion-input>
          </td>
          <td>
            <ion-icon name="search"></ion-icon>
          </td>
        </tr>
      </table>
    </ion-col>
  </ion-row>
</div>
