<ion-content>
  <ion-row>
    <ion-col class="team-sidebar">
      <app-team-listing-sidebar> </app-team-listing-sidebar>
    </ion-col>
    <ion-col class="team-content-wrapper">
      <ion-title class="ion-float-left">
        Team Members
      </ion-title>

      <div class="team-member-avatar">
        <app-user-avatar *ngIf="user" [user]="user"></app-user-avatar>
        <div class="team-member-info-details" *ngIf="teamMember?.teamMemberEmail || teamMember?.teamMemberPhoneNumber">
          <i class="icon-info"></i>
          <span class="team-member-email" *ngIf="teamMember?.teamMemberEmail">
            <a href="mailto:{{ teamMember.teamMemberEmail }}">{{ teamMember.teamMemberEmail }}</a>
          </span>
          <span class="team-member-phone" *ngIf="teamMember?.teamMemberPhoneNumber">
            <a href="tel:{{ teamMember.teamMemberPhoneNumber }}">{{ teamMember.teamMemberPhoneNumber }}</a>
          </span>
        </div>
      </div>

      <div class="team-member-info-container">
        <div class="team-member-info" *ngIf="teamMember">
          <div class="team-member-details-container">
            <span class="team-member-role">
              {{ teamMember.teamMemberRoleLabel }}
            </span>
            <span (click)="postNote(teamMember)" class="post-it-note"><i class="post-it"></i> <a>Post a note</a></span>
            <div class="greeting">
              <span class="english" *ngIf="!teamMember.spanishSpeaking; else spanish">
                Hello my name is...
              </span>
              <ng-template #spanish>
                <span class="spanish">Hola! Mi nombre es...</span>
              </ng-template>
            </div>
          </div>
          <div class="team-member-details">
            <h2 class="user-name">
              {{ teamMember.teamMemberFirstName }}
              {{ teamMember.teamMemberLastName }}
            </h2>
            <div class="additional-details" *ngIf="user">
              <span class="team-member-birthday">
                <span class="leader">Birthday: </span
                >{{ user.userDob ? (user.userDob | date: 'mm/dd/yy') : 'Not Provided' }}
              </span>
              <span class="team-member-dessert">
                <span class="leader">Favorite Dessert: </span
                >{{ user.userFavoriteDessert ? user.userFavoriteDessert : 'Not Provided' }}
              </span>
              <div class="user-information">
                <h3 class="about-me" *ngIf="additionalInfoDecoded || user.userInterests">ABOUT ME</h3>
                <p class="additional-info" [innerHTML]="additionalInfoDecoded"></p>
                <ul class="user-interests" *ngIf="user">
                  <li *ngFor="let interest of user.userInterests | keyvalue">
                    {{ interest.value.value ? '' : interest.value.nicename }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
