<div
  class="user-cork-board"
  [@openClose]="isOpen ? 'open' : 'closed'"
  [className]="isOpen ? 'user-cork-board open' : 'user-cork-board closed'"
  [class.deleting]="deleteMode"
>
  <ul class="corkboard-controls">
    <li (click)="toggleCorkboardState()" class="expand icon"></li>
  </ul>
  <ng-container *ngIf="isOpen && userCorkBoardObjects">
    <div class="corkboard-objects">
      <div *ngFor="let userCorkBoardObject of userCorkBoardObjects; let idx = index">
        <app-user-cork-board-object
          *ngIf="userCorkBoardObject && idx <= 2"
          [userCorkBoardObject]="userCorkBoardObject"
        ></app-user-cork-board-object>
      </div>
    </div>
  </ng-container>
</div>
