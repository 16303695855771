<!-- Filter UI -->
<section class="table-filter">
  <i class="filter-icon"></i>
  <div class="custom-select" (click)="toggleDropdown()">
    <span class="selected-sort-column">
      {{ selectedOption }}
    </span>
    <div class="dropdown" *ngIf="options" [class.hidden]="!dropdownOpen">
      <ul>
        <li *ngFor="let option of options">
          <a (click)="selectOption(option)">
            {{ option }}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="sort-controls">
    <i class="desc-control" [class.selected]="sortDirection == 'desc'" (click)="selectDescEvent()"></i>
    <i class="asc-control" [class.selected]="sortDirection == 'asc'" (click)="selectAscEvent()"></i>
  </div>
</section>
