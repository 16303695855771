<ul id="call-queue-legend">
  <li>
    <div><i class="icon-contacted"></i><span>Contacted</span></div>
  </li>
  <li>
    <div><i class="icon-left-message"></i><span>Left Message</span></div>
  </li>
  <li>
    <div><i class="icon-no-answer"></i><span>No Answer</span></div>
  </li>
  <li>
    <div class="todays-calls">
      <i class="icon-todays-calls">
        <span>#</span>
      </i>
      <span>Today's Calls</span>
    </div>
  </li>
  <li>
    <div class="discharges">
      <i class="icon-new-discharges">
        <span>#</span>
      </i>
      <span>New Discharges</span>
    </div>
  </li>
  <li>
    <div><i class="icon-kudos"></i><span>Kudos</span></div>
  </li>
  <li>
    <div><i class="icon-notifications"></i><span>Notification</span></div>
  </li>
</ul>
