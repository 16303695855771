<ion-list
  id="patientCallStatusControls"
  lines="none"
  class="patient-call-status-legend"
  [class.inactive]="patientCall.patientCallStatusLabel == 'Scheduled'"
>
  <ion-item
    *ngFor="let patientCallStatus of patientCallStatuses"
    class="patient-status-control"
    (click)="updatePatientCallStatus(patientCallStatus.patientCallStatusLabelId)"
  >
    <div>
      <i
        class="icon-{{ patientCallStatus.patientCallStatusLabel | lowercase }}"
        [class.active]="patientCall.patientCallStatusLabelId == patientCallStatus.patientCallStatusLabelId"
      ></i>
      <span [class.active]="patientCall.patientCallStatusLabelId == patientCallStatus.patientCallStatusLabelId"
        >{{ patientCallStatus.patientCallStatusLabel }}
      </span>
    </div>
  </ion-item>
  <ion-item class="patient-status-control" (click)="updateFinalCallStatus()">
    <div>
      <i class="icon-final" [class.active]="patientCall.finalCall"></i>
      <span [class.active]="patientCall.finalCall">
        Final Call
      </span>
    </div>
  </ion-item>
</ion-list>
