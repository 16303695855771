<ion-content>
  <ion-row>
    <ion-col class="left-hand-detail">
      <ng-container>
        <app-patient-summary-widget [patient]="patient"> </app-patient-summary-widget>
      </ng-container>
      <app-patient-notes [patient]="patient"> </app-patient-notes>
      <app-patient-history-listing
        *ngIf="patient.patientCalls && patient.patientNotifications"
        [patient]="patient"
        [patientCalls]="patient.patientCalls"
        [patientNotifications]="patient.patientNotifications"
      >
      </app-patient-history-listing>
    </ion-col>

    <ion-col class="right-hand-detail">
      <ng-container *ngIf="patientCall$ | async">
        <app-patient-call-status-controls
          [patientCall]="patientCall"
          (patientCallStatusChangeEmitter)="patientCallStatusLabelChangeHandler($event)"
          (patientFinalCallStatusChangeEmitter)="patientFinalCallChangeHandler($event)"
        >
        </app-patient-call-status-controls>
        <!-- this will be hidden if we have advanced past ID 3, which is the "started" state, but hide if it is the final call -->
        <app-patient-call-start-button
          *ngIf="
            patientCallStatuses[patientCall.patientCallStatusLabelId] < 3 &&
            patientCall.patientCallStatusLabel != 'Final Call'
          "
          [user]="user"
          [patientCall]="patientCall"
          (patientCallStartEventEmitter)="patientCallStartEventHandler($event)"
        >
        </app-patient-call-start-button>

        <!-- Hide our questions once we are in call status Review or Greater -->
        <app-patient-call-questions
          *ngIf="
            patientCall.patientCallStatusLabel == 'Started' ||
            patientCall.patientCallStatusLabel == 'User Selected Status'
          "
          [patientCall]="patientCall"
          (patientCallAnwersChangeEmitter)="patientCallQuestionsChangeHandler($event)"
        >
        </app-patient-call-questions>
        <app-patient-call-notes
          *ngIf="
            patientCall.patientCallStatusLabel == 'Started' ||
            patientCall.patientCallStatusLabel == 'In Review' ||
            patientCall.patientCallStatusLabel == 'User Selected Status' ||
            patientCall.patientCallStatusLabel == 'Final Call'
          "
          [patientCall]="patientCall"
          (patientCallNotesChangeEmitter)="patientCallNotesChangeHandler($event)"
          (patientCallNotesHighlightedChangeEmitter)="patientCallNotesHighlightedChangeHandler($event)"
        >
        </app-patient-call-notes>

        <app-patient-next-call-questions
          (patientNextCallQuestionsEmitter)="patientNextCallQuestionsChangeHandler($event)"
          *ngIf="
            patientCall.patientCallStatusLabel == 'Started' ||
            patientCall.patientCallStatusLabel == 'User Selected Status'
          "
        >
        </app-patient-next-call-questions>
        <app-patient-call-stop-button
          [patientCall]="patientCall"
          *ngIf="
            patientCall.patientCallStatusLabel == 'Started' ||
            patientCall.patientCallStatusLabel == 'User Selected Status'
          "
          (patientCallEndEventEmitter)="patientCallEndEventHandler(patientCall)"
        >
        </app-patient-call-stop-button>
        <ng-container *ngIf="patientCall.patientCallStatusLabel == 'In Review' && !patientCall.finalCall">
          <app-patient-next-call-scheduler
            (patientNextCallDateSelectedEventEmitter)="patientNextCallDateSelectedEventHandler($event)"
          >
          </app-patient-next-call-scheduler>
          <app-review-patient-next-call-questions
            *ngIf="patientCall.patientCallStatusLabel == 'In Review'"
            [patientCallQuestions]="patientNextCallQuestions"
          >
          </app-review-patient-next-call-questions>
          <app-patient-next-call-finish-button
            *ngIf="patientCall.patientCallStatusLabel == 'In Review'"
            [patient]="patient"
            [patientCall]="patientCall"
            (patientCallFinalizeEventEmitter)="patientCallFinishEventHandler(patientCall)"
          >
            Save
          </app-patient-next-call-finish-button>
        </ng-container>
        <ng-container *ngIf="patientCall.finalCall && patientCall.patientCallStatusLabel == 'In Review'">
          <app-followup-complete-button
            class="ion-float-right"
            *ngIf="patient"
            [patient]="patient"
            [patientCall]="patientCall"
            (patientCallFinalizeEventEmitter)="patientCallFinishEventHandler(patientCall)"
          >
          </app-followup-complete-button>
        </ng-container>
      </ng-container>
    </ion-col>
  </ion-row>
</ion-content>
