import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Patient, PatientDischargeLabel } from '@app/modules/patient/patient';
import { PatientService } from '@app/modules/patient/patient.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/modules/user/user';
import { PatientPutBody } from './patient-form';
import { PatientContact, PatientContactPostBody, PatientContactPutBody } from '../patient-contact/patient-contact';
import { PatientContactService } from '../patient-contact/patient-contact.service';
import { Operation } from '@app/modules/operation/operation';
import {
  PatientIntakeQuestion,
  PatientIntakeQuestionAnswer
} from '../patient-intake-question/patient-intake-question.component';
import { PatientIntakeQuestionService } from '../patient-intake-question/patient-intake-question.service';
import { SafeStyle } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { NgxImageCompressService } from 'ngx-image-compress';
import { formatDate } from '@angular/common';
import { UserService } from '@app/modules/user/user.service';

@Component({
  providers: [NgxImageCompressService, PatientService, PatientIntakeQuestionService],
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss']
})
export class PatientFormComponent implements OnInit {
  avatarExists: Boolean;
  public avatarUrl: SafeStyle;
  dischargeLabels: PatientDischargeLabel[];
  dischargedTo: string = 'Home';
  patientForm: FormGroup;
  currentYear: number;
  mode: any = {
    add: null,
    edit: null
  };
  patient: Patient;
  patient$: Observable<Patient> | void;
  patientContacts: PatientContact[] = [];
  patientContactsOriginal: PatientContact[] = [];
  patientContactsToAdd: PatientContact[] = [];
  patientContactsToEdit: PatientContact[] = [];
  patientContactsToRemove: string[] = [];
  patientContacts$: Observable<PatientContact[]>;
  patientContactRelationships = ['Spouse', 'Child', 'Parent', 'Relative', 'Friend', 'Sig. Other', 'Other'];
  patientIntakeQuestions: PatientIntakeQuestion[] = [];
  patientIntakeQuestions$: Observable<PatientIntakeQuestion[]>;
  patientIntakeQuestionAnswersOriginal: {
    patientIntakeQuestionId: string;
    patientIntakeQuestionAnswer: string;
  }[] = [];
  patientIntakeQuestionAnswers: PatientIntakeQuestionAnswer[] = [];
  patientIntakeQuestionAnswersToAdd: PatientIntakeQuestionAnswer[] = [];
  patientMaxAdmitDate: string = new Date().getFullYear().toString();
  // default to 2019 as our first year
  patientMinDischargeDate: string = (new Date().getFullYear() + 1).toString();
  patientMedicalConditions?: string;
  operations: Operation[];
  operations$: Observable<Operation[]>;
  stringMinimumOneWordRegEx = RegExp(/^(?!\s*$).+/);

  user: User;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private patientContactService: PatientContactService,
    private patientIntakeQuestionService: PatientIntakeQuestionService,
    private toastrService: ToastrService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.user = this.route.snapshot.data.user;
    this.operations = this.user.operations;
    this.patientService.getPatientDischargeLabels().subscribe((data: any) => {
      this.dischargeLabels = data;
      this.dischargedTo = data;
    });

    if (this.route.snapshot.data.mode == 'edit') {
      this.mode.edit = true;
      this.patient = this.route.snapshot.data.patient;
    } else if (this.route.snapshot.data.mode == 'add') {
      this.mode.add = true;
    }
    if (this.mode.add) {
      /**
       * Creating a shell of the patient object within the database first
       * allows us to post avatars, uploads, etc. to a known patientId
       * even before the patient is "real".
       */
      this.patientService.addNewPatient().subscribe((data: any) => {
        if (data.patientId) {
          let patientId = data.patientId;
          // Set some defaults
          this.patient = {
            patientId: patientId,
            patientDischargeLabelId: null
          };
        }
        this.patient.patientMedicalConditions = {
          cardiacBoolean: false,
          sepsisBoolean: false,
          pulmonaryBoolean: false,
          otherBoolean: false
        };
        this.createForm();
        this.addAdditionalPatientContact();
        this.patientIntakeQuestionService
          .getPatientIntakeQuestionsByPatientId(this.patient.patientId)
          .subscribe((patientIntakeQuestions: PatientIntakeQuestion[]) => {
            let patientIntakeQuestionAnswers = this.patientForm.get(
              'patient.patientIntakeQuestionAnswers'
            ) as FormArray;
            patientIntakeQuestions.forEach((patientIntakeQuestion: PatientIntakeQuestion, index: number) => {
              let patientIntakeQuestionId = patientIntakeQuestion['patientIntakeQuestionId'].toString();
              let newFormGroup = this.fb.group({});
              let newControl = new FormControl('');
              newFormGroup.addControl(patientIntakeQuestionId, newControl);
              patientIntakeQuestionAnswers.push(newFormGroup);
              this.patientIntakeQuestions.push(patientIntakeQuestion);
            });
          });
      });

      this.patientForm.get('patient.dischargeInfo.patientDischargedTo').setValue('2PEXyKgz');
    } else {
      this.patientService
        .getPatientByPatientId(this.patient.patientId)
        .pipe(take(1))
        .subscribe((data: Patient) => {
          this.patient = data[0];
          let medicalConditions = JSON.parse(this.patient.patientMedicalConditions);
          // Once we've got our data set from JSON, let's re-set the individual properties.
          this.patient.patientMedicalConditions = {};
          if (medicalConditions !== null) {
            this.patient.patientMedicalConditions.sepsisBoolean = medicalConditions.sepsisBoolean;
            this.patient.patientMedicalConditions.cardiacBoolean = medicalConditions.cardiacBoolean;
            this.patient.patientMedicalConditions.pulmonaryBoolean = medicalConditions.pulmonaryBoolean;
            this.patient.patientMedicalConditions.otherBoolean = medicalConditions.otherBoolean;
          }
          this.createForm();

          // We need to explicitly set this value we learned from testing.
          this.patientForm
            .get('patient.dischargeInfo.patientDischargedTo')
            .setValue(this.patient.patientDischargeLabelId ? this.patient.patientDischargeLabelId : '2PEXyKgz');
          this.patientContacts$ = this.patientContactService.getPatientContactsByPatientId(this.patient.patientId);
          this.patientContacts$.subscribe((patientContacts: PatientContact[]) => {
            let patientContactArray = this.patientForm.get('patient.patientContacts') as FormArray;
            if (patientContacts) {
              this.patientContacts.splice(0, 1);
              patientContacts.forEach((patientContact: PatientContact, idx: number) => {
                patientContact.patientContactOrder = (idx + 1).toString();
                patientContactArray.push(
                  this.fb.group({
                    patientContactFirstName: this.fb.control(patientContact.patientContactFirstName),
                    patientContactLastName: this.fb.control(patientContact.patientContactLastName),
                    patientContactRelationship: this.fb.control(patientContact.patientContactRelationship),
                    patientContactCountryCode: this.fb.control(patientContact.patientContactCountryCode, [
                      Validators.pattern(/^[0-9]\d*$/)
                    ]),
                    patientContactAreaCode: this.fb.control(patientContact.patientContactAreaCode, [
                      Validators.pattern(/^[0-9]\d*$/)
                    ]),
                    patientContactPhoneNumber: this.fb.control(patientContact.patientContactPhoneNumber, [
                      Validators.pattern(/^[0-9-]{7,}\d*$/)
                    ]),
                    patientContactOrder: this.fb.control(patientContact.patientContactOrder, [
                      Validators.pattern(/^[0-9]\d*$/)
                    ]),
                    patientContactHIPAABoolean: this.fb.control(patientContact.patientContactHIPAABoolean),
                    patientContactResponsiblePartyBoolean: this.fb.control(
                      patientContact.patientContactResponsiblePartyBoolean
                    )
                  })
                );
                this.patientContactsOriginal.push(patientContact);
                this.patientContacts.push(patientContact);
              });
            }
          });

          this.patientIntakeQuestionService
            .getPatientIntakeQuestionsByPatientId(this.patient.patientId)
            .subscribe((patientIntakeQuestions: PatientIntakeQuestion[]) => {
              let patientIntakeQuestionAnswers = this.patientForm.get(
                'patient.patientIntakeQuestionAnswers'
              ) as FormArray;
              patientIntakeQuestions.forEach((patientIntakeQuestion: PatientIntakeQuestion, index: number) => {
                let newFormGroup = this.fb.group({});
                this.patientIntakeQuestionService
                  .getPatientIntakeQuestionAnswersByPatientIntakeQuestionId(
                    patientIntakeQuestion.patientIntakeQuestionId
                  )
                  .subscribe((patientIntakeQuestionAnswer: PatientIntakeQuestionAnswer) => {
                    if (patientIntakeQuestionAnswer !== null) {
                      var data = patientIntakeQuestionAnswer[0];
                      var patientIntakeQuestionId = data.patientIntakeQuestionId.toString();
                      var patientIntakeQuestionAnswerValue = data.patientIntakeQuestionAnswer;
                      newFormGroup.addControl(
                        patientIntakeQuestionId,
                        new FormControl(patientIntakeQuestionAnswerValue)
                      );
                      patientIntakeQuestionAnswers.push(newFormGroup);
                      var dataObject = <any>{};
                      dataObject[patientIntakeQuestionId] = patientIntakeQuestionAnswerValue;
                      this.patientIntakeQuestionAnswersOriginal.push(dataObject);
                      this.patientIntakeQuestions.push(patientIntakeQuestion);
                    } else {
                      newFormGroup.addControl(
                        patientIntakeQuestion.patientIntakeQuestionId.toString(),
                        new FormControl('')
                      );
                      patientIntakeQuestionAnswers.push(newFormGroup);
                      this.patientIntakeQuestions.push(patientIntakeQuestion);
                    }
                  });
              });
            });
        });
    }
  }

  private createForm() {
    this.patientForm = this.fb.group({
      patient: this.fb.group({
        operation: this.fb.control(this.patient.patientOperationId, [Validators.required]),
        patientMedicalRecordNumber: this.fb.control(this.patient.patientMedicalRecordNumber, [
          Validators.required,
          Validators.pattern(this.stringMinimumOneWordRegEx)
        ]),
        patientName: this.fb.group({
          patientFirstName: this.fb.control(this.patient.patientFirstName, [
            Validators.required,
            Validators.pattern(this.stringMinimumOneWordRegEx)
          ]),
          patientLastName: this.fb.control(this.patient.patientLastName, [
            Validators.required,
            Validators.pattern(this.stringMinimumOneWordRegEx)
          ])
        }),
        patientDob: this.fb.control(
          this.patient.patientDob ? formatDate(this.patient.patientDob, 'yyyy-MM-dd', 'en') : '',
          [Validators.required]
        ),
        patientGender: this.fb.control(this.patient.patientGender),
        patientCountryCode: this.fb.control(
          this.patient.patientCountryCode ? this.patient.patientCountryCode.toString() : '1'
        ),
        patientAreaCode: this.fb.control(this.patient.patientAreaCode),
        patientPhoneNumber: this.fb.control(this.patient.patientPhoneNumber, [Validators.pattern(/^[-0-9]{8,}\d*$/)]),
        patientHIPAA: this.fb.control(this.patient.patientHIPAA),
        patientIsResponsibleParty: this.fb.control(this.patient.patientIsResponsibleParty),
        patientSpeaksEnglish: this.fb.control(
          typeof this.patient.patientSpeaksEnglish == 'undefined' || this.patient.patientSpeaksEnglish == true
            ? false
            : true
        ),
        patientFluentLanguage: this.fb.control(
          this.patient.patientFluentLanguage ? this.patient.patientFluentLanguage : 'English'
        ),
        patientContacts: this.fb.array([]),
        primaryCarePhysician: this.fb.group({
          patientPhysicianName: this.fb.control(this.patient.patientPhysicianName),
          patientPhysicianPhoneNumber: this.fb.control(this.patient.patientPhysicianPhoneNumber)
        }),
        insurance: this.fb.group({
          primaryInsurance: this.fb.control(this.patient.patientPrimaryInsurance)
        }),
        dischargeInfo: this.fb.group({
          patientAdmitDate: this.fb.control(
            this.patient.patientAdmitDate ? formatDate(this.patient.patientAdmitDate, 'yyyy-MM-dd', 'en') : '',
            [Validators.required]
          ),
          patientDischargeDate: this.fb.control(
            this.patient.patientDischargeDate
              ? formatDate(this.patient.patientDischargeDate, 'yyyy-MM-dd', 'en')
              : null,
            [Validators.required]
          ),
          patientTotalDays: this.fb.control({
            disabled: true,
            value: this.patient.patientTotalDays
          }),
          patientDischargedTo: this.fb.control(
            this.patient.patientDischargeLabelId ? this.patient.patientDischargeLabelId : '2PEXyKgz',
            [Validators.required]
          ),
          patientDischargedAma: this.fb.control((this.patient.patientDischargedAma == true ? '1' : '0') || '0', [
            Validators.required
          ])
        }),
        patientMedicalConditions: this.fb.group({
          cardiacBoolean: this.fb.control(this.patient.patientMedicalConditions.cardiacBoolean == true ? 1 : 0),
          sepsisBoolean: this.fb.control(this.patient.patientMedicalConditions.sepsisBoolean == true ? 1 : 0),
          pulmonaryBoolean: this.fb.control(this.patient.patientMedicalConditions.pulmonaryBoolean == true ? 1 : 0),
          otherBoolean: this.fb.control(this.patient.patientMedicalConditions.otherBoolean == true ? 1 : 0)
        }),
        patientPrimaryDiagnosis: this.fb.control(this.patient.patientPrimaryDiagnosis, [
          Validators.required,
          Validators.pattern(this.stringMinimumOneWordRegEx)
        ]),
        patientDischargedCondition: this.fb.control(this.patient.patientDischargedCondition),
        patientIntakeQuestionAnswers: this.fb.array([]),
        patientNeedToKnow: this.fb.control(this.patient.patientNeedToKnow),
        patientActive: this.fb.control(this.patient.patientActive)
      })
    });
  }

  addAdditionalPatientContact() {
    this.patientContacts.push({
      patientContactFirstName: '',
      patientContactLastName: '',
      patientContactRelationship: '',
      patientContactCountryCode: '1',
      patientContactAreaCode: '',
      patientContactPhoneNumber: '',
      patientContactOrder: (this.patientContacts.length + 1).toString(),
      patientContactHIPAABoolean: false,
      patientContactResponsiblePartyBoolean: false
    });
    let patientContactArray = this.patientForm.get('patient.patientContacts') as FormArray;
    let idx = patientContactArray.length;
    patientContactArray.push(
      this.fb.group({
        patientContactFirstName: this.fb.control(''),
        patientContactLastName: this.fb.control(''),
        patientContactRelationship: this.fb.control(''),
        patientContactCountryCode: this.fb.control(1, Validators.pattern(/^[0-9]\d*$/)),
        patientContactAreaCode: this.fb.control('', Validators.pattern(/^[0-9]\d*$/)),
        patientContactPhoneNumber: this.fb.control(''),
        patientContactOrder: this.fb.control(idx + 1),
        patientContactHIPAABoolean: this.fb.control(false),
        patientContactResponsiblePartyBoolean: this.fb.control(false)
      })
    );
  }

  removeAdditionalPatientContact(idx: number) {
    let patientContactArray = this.patientForm.get('patient.patientContacts') as FormArray;
    patientContactArray.at(idx).clearValidators();
    patientContactArray.removeAt(idx);
    this.patientContactsToRemove.push(this.patientContacts[idx].patientContactId);
    this.patientContacts.splice(idx, 1);
    // Reset new contact order
    this.patientContacts.forEach((patientContact, idx) => {
      this.patientContacts[idx].patientContactOrder = (idx + 1).toString();
    });
  }
  selectPatientContactRelationship(relationship: string, patientContact: PatientContact) {
    patientContact.patientContactRelationship = relationship;
  }
  updateDischargeFields() {
    let startDate = this.patientForm.get('patient.dischargeInfo.patientAdmitDate').value;
    let endDate = this.patientForm.get('patient.dischargeInfo.patientDischargeDate').value;
    if (!startDate || !endDate) {
      if (startDate) {
        this.patientMinDischargeDate = this.patientForm
          .get('patient.dischargeInfo.patientAdmitDate')
          .value.substr(0, 10);
      } else if (endDate) {
        this.patientMaxAdmitDate = this.patientForm
          .get('patient.dischargeInfo.patientDischargeDate')
          .value.substr(0, 10);
      }
      return;
    }
    // Have to feed back the min and max in specific formats, see https://ionicframework.com/docs/api/datetime#properties
    this.patientMaxAdmitDate = this.patientForm.get('patient.dischargeInfo.patientDischargeDate').value.substr(0, 10);
    this.patientMinDischargeDate = this.patientForm.get('patient.dischargeInfo.patientAdmitDate').value.substr(0, 10);
    // To calculate the time difference of two dates
    var timeDiff = new Date(endDate).getTime() - new Date(startDate).getTime();
    var dayDiff = Math.round(timeDiff / (1000 * 3600 * 24));
    this.patientForm.get('patient.dischargeInfo.patientTotalDays').setValue(dayDiff);
  }
  deletePatient(patientId: number): void {
    if (confirm('This will permanently delete the patient and their history. \
    Are you sure you want to do this?')) {
      this.patientService.deletePatientByPatientId(this.patient.patientId).subscribe(() => {
        this.toastrService.success('Patient Successfully Deleted');
        this.userService.updateOperations(this.user).then(res => {
          window.location.href = '/operations/' + this.patient.patientOperationId + '/patients';
        });
      });
    }
  }
  cancel(): void {
    window.location.reload();
  }
  onFormSubmit(): void {
    if (!this.validateControls()) {
      return;
    }
    let formSubmission = this.patientForm.getRawValue();
    /**
     * Run processing on our patient intake questions
     */
    let intakeAnswers = this.patientForm.controls.patient.get('patientIntakeQuestionAnswers') as FormArray;
    let intakeAnswersArray = intakeAnswers.getRawValue();

    /**
     * Add answers if we don't have them yet, we do this by comparing the objects
     */

    if (this.patientIntakeQuestionAnswersOriginal.length) {
      this.patientIntakeQuestionAnswersToAdd = intakeAnswersArray.filter(
        (patientContactQuestionAnswer: any, index: number) => {
          return (
            Object.is(patientContactQuestionAnswer[index], this.patientIntakeQuestionAnswersOriginal[index]) &&
            patientContactQuestionAnswer[index] !== undefined
          );
        }
      );
    } else {
      this.patientIntakeQuestionAnswersToAdd = intakeAnswersArray;
    }

    this.patientIntakeQuestionAnswersToAdd.forEach((patientIntakeQuestionAnswer: PatientIntakeQuestionAnswer) => {
      var patientIntakeQuestionId = Object.keys(patientIntakeQuestionAnswer).toString();
      var patientQuestionAnswer = patientIntakeQuestionAnswer[patientIntakeQuestionId];
      this.patientIntakeQuestionService
        .addPatientIntakeQuestionAnswerByPatientIntakeQuestionId(patientIntakeQuestionId, patientQuestionAnswer)
        .subscribe((data: any) => {});
    });
    /**
     * Edit questions if we already had them
     */
    intakeAnswersArray.forEach((patientIntakeQuestionAnswer: any) => {
      var patientIntakeQuestionId = Object.keys(patientIntakeQuestionAnswer).toString();
      var patientQuestionAnswer = patientIntakeQuestionAnswer[patientIntakeQuestionId];
      this.patientIntakeQuestionService
        .editPatientIntakeQuestionAnswerByPatientIntakeQuestionId(patientIntakeQuestionId, patientQuestionAnswer)
        .subscribe((data: any) => {});
    });

    // Passing E2E
    this.patientContactsToRemove.forEach((patientContactId: string, index: number) => {
      this.patientContactService.removePatientContactByPatientContactId(patientContactId).subscribe(() => {
        this.toastrService.success('Successfully removed patient contact');
      });
    });

    /**
     * Get a diff from our original patient contacts
     */
    this.patientContactsToAdd = this.patientContacts.filter((patientContact: PatientContact) => {
      return this.patientContactsOriginal.indexOf(patientContact) == -1;
    });

    // Passing E2E
    this.patientContactsToAdd.forEach((patientContact: PatientContact, index: number) => {
      var indexToGrab = parseInt(patientContact.patientContactOrder) - 1;
      this.patientContacts[indexToGrab] = formSubmission.patient.patientContacts[indexToGrab];
      var patientContactPost = this.patientContactPostFactory(this.patientContacts[indexToGrab]);
      this.patientContactService
        .addNewPatientContactByPatientId(this.patient.patientId, patientContactPost)
        .subscribe(() => {
          this.toastrService.success('Successfully added patient contact');
        });
    });

    if (this.patientContacts.length) {
      this.patientContactsToEdit = this.patientContacts.filter((patientContact: any, index: number) => {
        if (!patientContact.patientContactId) {
          return false;
        }
        /**
         * Get the actual form submission value and then compare it to see if we need to edit
         */
        var indexToGrab = parseInt(patientContact.patientContactOrder) - 1;
        // Set patient contact id since we have no form control.
        formSubmission.patient.patientContacts[indexToGrab].patientContactId = patientContact.patientContactId;
        formSubmission.patient.patientContacts[indexToGrab].patientId = this.patient.patientId;
        this.patientContacts[indexToGrab] = formSubmission.patient.patientContacts[indexToGrab];
        // Use lodash to see if these are deep-equal
        return !_.isEqual(patientContact, this.patientContacts[indexToGrab]);
      });
      if (!this.patientContactsToEdit.length) {
        this.editPatient(formSubmission);
      }

      this.patientContactsToEdit.forEach((patientContact: PatientContact, index: number) => {
        // Now that we have these, we need to reassign to the form-submitted value.
        var indexToGrab = parseInt(patientContact.patientContactOrder) - 1;
        this.patientContacts[indexToGrab] = formSubmission.patient.patientContacts[indexToGrab];
        var patientContactPut = this.patientContactPutFactory(this.patientContacts[indexToGrab]);

        this.patientContactService
          .editPatientContactByPatientId(this.patientContacts[indexToGrab].patientContactId, patientContactPut)
          .subscribe(() => {
            this.toastrService.success('Successfully edited patient contact');
            /**
             * Now go ahead and save other patient details
             */
            this.editPatient(formSubmission);
          });
      });
    } else {
      this.editPatient(formSubmission);
    }
  }
  editPatient(formSubmission: any) {
    let patientPutBody = this.formSubmissionFactory(formSubmission);

    this.patientService.editPatientByPatientId(this.patient.patientId, patientPutBody).subscribe(value => {
      this.toastrService.success('Successfully edited patient!');

      this.userService.updateOperations(this.user).then(res => {
        window.location.href = '/operations/' + this.patientForm.get('patient.operation').value + '/patients';

        if (!this.mode.edit) {
          this.patientForm.reset();
        }
      });
    });
  }
  patientContactPutFactory(patientContact: PatientContact): PatientContactPutBody {
    try {
      var payload = {
        patientContactId: patientContact.patientContactId,
        patientContactFirstName: patientContact.patientContactFirstName.toString(),
        patientContactLastName: patientContact.patientContactLastName.toString(),
        patientContactRelationship: patientContact.patientContactRelationship.toString(),
        patientContactCountryCode: patientContact.patientContactCountryCode.toString(),
        patientContactAreaCode: patientContact.patientContactAreaCode.toString(),
        patientContactPhoneNumber: patientContact.patientContactPhoneNumber.toString(),
        patientContactOrder: parseInt(patientContact.patientContactOrder),
        patientContactHIPAABoolean: patientContact.patientContactHIPAABoolean == true ? 1 : 0,
        patientContactResponsiblePartyBoolean: patientContact.patientContactResponsiblePartyBoolean == true ? 1 : 0
      };
      return <PatientContactPutBody>payload;
    } catch {
      throw 'Had a problem validating data in the call rep factory';
    }
  }
  patientContactPostFactory(patientContact: PatientContact): PatientContactPostBody {
    try {
      var payload = {
        patientId: this.patient.patientId,
        patientContactFirstName: patientContact.patientContactFirstName.toString(),
        patientContactLastName: patientContact.patientContactLastName.toString(),
        patientContactRelationship: patientContact.patientContactRelationship.toString(),
        patientContactCountryCode: patientContact.patientContactCountryCode.toString(),
        patientContactAreaCode: patientContact.patientContactAreaCode.toString(),
        patientContactPhoneNumber: patientContact.patientContactPhoneNumber.toString(),
        patientContactOrder: parseInt(patientContact.patientContactOrder),
        patientContactHIPAABoolean: patientContact.patientContactHIPAABoolean == true ? 1 : 0,
        patientContactResponsiblePartyBoolean: patientContact.patientContactResponsiblePartyBoolean == true ? 1 : 0
      };
      return <PatientContactPostBody>payload;
    } catch {
      throw 'Had a problem validating data in the patient contact factory!';
    }
  }
  /**
   * create a tidy type-checked payload to send off to the API
   * we do all of our processing to agree with Swagger contract here
   * @param formSubmission
   */
  private formSubmissionFactory(formSubmission: any) {
    /**
     * Do transforms so we have proper UTC times on the dates
     */
    var patientDob = formSubmission.patient.patientDob.substr(0, 10) + 'T12:00:00.00Z';
    var patientAdmitDate = formSubmission.patient.dischargeInfo.patientAdmitDate.substr(0, 10) + 'T12:00:00.00Z';
    var patientDischargeDate =
      formSubmission.patient.dischargeInfo.patientDischargeDate.substr(0, 10) + 'T12:00:00.00Z';

    const patientMedicalConditions = JSON.stringify(formSubmission.patient.patientMedicalConditions);
    var payload = {
      patientDob: patientDob,
      patientOperationId: formSubmission.patient.operation,
      patientMedicalRecordNumber: formSubmission.patient.patientMedicalRecordNumber,
      patientFirstName: formSubmission.patient.patientName.patientFirstName,
      patientLastName: formSubmission.patient.patientName.patientLastName,
      patientCountryCode: formSubmission.patient.patientCountryCode || '',
      patientAreaCode: formSubmission.patient.patientAreaCode || '',
      patientPhoneNumber: formSubmission.patient.patientPhoneNumber || '',
      patientGender: formSubmission.patient.patientGender,
      patientHIPAA: formSubmission.patient.patientHIPAA == true ? 1 : 0,
      patientIsResponsibleParty: formSubmission.patient.patientIsResponsibleParty == true ? 1 : 0,
      patientSpeaksEnglish: formSubmission.patient.patientSpeaksEnglish == true ? 1 : 0,
      patientFluentLanguage: formSubmission.patient.patientFluentLanguage,
      patientPhysicianName: formSubmission.patient.primaryCarePhysician.patientPhysicianName || '',
      patientPhysicianPhoneNumber: formSubmission.patient.primaryCarePhysician.patientPhysicianPhoneNumber || '',
      patientPrimaryInsurance: formSubmission.patient.insurance.primaryInsurance || '',
      patientAdmitDate: patientAdmitDate,
      patientDischargeDate: patientDischargeDate,
      patientDischargedAma: formSubmission.patient.dischargeInfo.patientDischargedAma == true ? 1 : 0,
      patientDischargeLabelId: formSubmission.patient.dischargeInfo.patientDischargedTo,
      patientDischargedCondition: formSubmission.patient.patientDischargedCondition
        ? formSubmission.patient.patientDischargedCondition
        : '',
      patientPrimaryDiagnosis: formSubmission.patient.patientPrimaryDiagnosis,
      patientMedicalConditions: patientMedicalConditions,
      patientNeedToKnow: formSubmission.patient.patientNeedToKnow || '',
      patientActive: formSubmission.patient.patientActive == true ? 1 : 0
    };
    return <PatientPutBody>payload;
  }

  /**
   * A function to validate controls,
   * and if there are any validation errors,
   * bounce the user to the top.
   */
  validateControls(): boolean {
    const firstError = <HTMLElement>document.querySelectorAll('ion-item .ng-invalid')[0];

    function scroll(el: HTMLElement) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
    if (firstError) {
      console.log(firstError);
      // scroll(firstError);
      alert('Failed validation, please check fields');
      return false;
    } else {
      return true;
    }
  }
  ngOnDestroy() {
    this.patient = null;
  }
}
