<form [formGroup]="followupCompleteForm" novalidate>
  <ng-container *ngIf="patient">
    <ion-row class="graduation-container">
      <ion-title>Follow-up</ion-title>
      <img class="ion-float-right graduation-image" src="/assets/complete-graphic@2x.png" />
    </ion-row>
    <ion-row class="patient-status-container">
      <ul class="graduation-options-container" lines="none">
        <ion-radio-group formControlName="patientStatusLabelId">
          <li *ngFor="let patientStatus of completionTypes">
            <ion-item>
              <ion-radio class="radio-button-notify" mode="ios" value="{{ patientStatus.patientStatusLabelId }}">
                {{ patientStatus.patientStatusLabelId }}
              </ion-radio>
              <ion-label class="patient-status-label ion-text-wrap">
                {{ patientStatus.patientStatusLabel }}
              </ion-label>
            </ion-item>
          </li>
        </ion-radio-group>
      </ul>
    </ion-row>
    <ion-textarea formControlName="completionNotes" placeholder="Any Notes?"> </ion-textarea>
    <div class="send-container">
      <button class="secondary button" (click)="dismiss()">Cancel</button>
      <button class="primary button" type="submit" (click)="archivePatient(patient)">Save</button>
    </div>
  </ng-container>
</form>
