<nav [class.dropdown-active]="dropdownActivated" *ngIf="user && navLinks" (mouseleave)="closeDropdowns()">
  <ion-list lines="none">
    <div class="nav-link-container" *ngFor="let link of navLinks; let i = index">
      <ion-item *ngIf="link.minRole >= userRolesArray[user.userLevel]">
        <a *ngIf="!link.dropdown.links && !link.dynamic" (click)="closeDropdowns()" routerLink="/{{ link.linkAction }}">
          <i *ngIf="link.linkIcon" class="{{ link.linkIcon }}"></i>
          {{ link.linkName }}
        </a>
        <a *ngIf="link.dropdown.links" (mouseover)="openDropdown(i)" (click)="toggleDropdown(i)">
          <i *ngIf="link.linkIcon" class="{{ link.linkIcon }}"></i>
          <a *ngIf="!link.dropdown.links" routerLink="/{{ link.linkAction }}">{{ link.linkName }}</a>
          <a *ngIf="link.dropdown.links">{{ link.linkName }}</a>
          <i class="nav-caret" *ngIf="link.dropdown.links" (click)="toggleDropdown(i)" (touch)="openDropdown(i)"></i>
        </a>
        <a *ngIf="link.dynamic" [class.disabled]="!callQueuePage" (click)="dynamicLink(link)">
          <i *ngIf="link.linkIcon" class="{{ link.linkIcon }}"></i>
          {{ link.linkName }}
        </a>
      </ion-item>
      <div *ngIf="dropdowns[i]">
        <div
          [class.active]="dropdowns[i].activated"
          class="dropdown"
          *ngIf="link.dropdown.links"
          (mouseenter)="openDropdown(i)"
          (mouseleave)="closeDropdown(i)"
        >
          <ul *ngIf="link.minRole >= userRolesArray[user.userLevel]">
            <li *ngFor="let link of link.dropdown.links">
              <a *ngIf="!link.dynamic" (click)="closeDropdowns()" routerLink="/{{ link.linkAction }}">
                {{ link.linkName }}</a
              >
              <a *ngIf="link.dynamic" (click)="dynamicLink(link)">
                {{ link.linkName }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ion-list>
</nav>
