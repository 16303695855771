<i class="icon-discharge"></i>
<ion-list class="inline-discharge-section left-column" inset lines="none">
  <ion-item class="discharged-date-item">
    <span class="label">
      Discharged: &nbsp;
    </span>
    <span class="discharge-date">
      {{ patient.patientDischargeDate | date: 'MM/dd/yyyy':'UTC' }}
    </span>
  </ion-item>
  <ion-item>
    <span class="label">Admitted: &nbsp; </span>
    {{ patient.patientAdmitDate | date: 'MM/dd/yyyy':'UTC' }}
  </ion-item>
</ion-list>

<ion-list class="inline-discharge-section right-column" inset lines="none">
  <ion-item>
    <span class="label">Length of Stay: &nbsp; </span>
    {{ patient.patientTotalDays }} Days
  </ion-item>
  <ion-item class="discharged-to">
    <span class="label">Discharged To: &nbsp;</span
    ><span class="label ama" *ngIf="patient.patientDischargedAma">(AMA)&nbsp;</span>{{ patient.patientDischargeLabel }}
  </ion-item>
</ion-list>
<br />
<i class="icon-medical"></i>
<ion-list class="more-info-section" inset lines="none">
  <ion-item>
    <span class="medical-condition-label label">
      Medical Conditions: &nbsp;
    </span>
    <label
      class="medical-condition-item"
      *ngFor="let patientMedicalCondition of patientMedicalConditions; let i = index"
    >
      <span [class.first]="i == 0" [class.other]="patientMedicalCondition == 'otherBoolean'">
        {{ patientMedicalCondition.replace('Boolean', '') }}</span
      >
    </label>
    <span *ngIf="patientMedicalConditions.length == 0">(none provided)</span>
  </ion-item>
  <ion-item class="primary-diagnosis">
    <span class="label">Primary Diagnosis: &nbsp; </span> {{ patient.patientPrimaryDiagnosis }}
  </ion-item>
  <div class="discharged-condition">
    <span class="discharge label">Discharged Condition: &nbsp; </span>
    <p>{{ patient.patientDischargedCondition.length ? patient.patientDischargedCondition : '(none provided)' }}</p>
  </div>
  <div class="need-to-know" *ngIf="patient.patientNeedToKnow">
    <span class="label"> Need To Know: &nbsp; </span>
    <p>
      {{ patient.patientNeedToKnow.length ? patient.patientNeedToKnow : '(no additional information given)' }}
    </p>
  </div>
  <ion-list class="intake-questions">
    <ion-item *ngFor="let patientIntakeQuestion of patientIntakeQuestions">
      <span class="label">{{ patientIntakeQuestion.patientIntakeQuestion }}: &nbsp;</span>
      <ng-container *ngIf="patientIntakeQuestion.patientIntakeQuestionAnswer; else noAnswer">
        <ng-container *ngIf="patientIntakeQuestion.patientQuestionTypeLabel == 'boolean'; else nonBoolean">
          {{ patientIntakeQuestion.patientIntakeQuestionAnswer === '1' ? 'Yes' : 'No' }}
        </ng-container>
        <ng-template #nonBoolean>
          {{ patientIntakeQuestion.patientIntakeQuestionAnswer }}
        </ng-template>
      </ng-container>
      <ng-template #noAnswer>
        N/A
      </ng-template>
    </ion-item>
  </ion-list>
</ion-list>
