<div class="login-background"></div>
<ion-content no-bounce>
  <div class="login-container">
    <form id="login-form" (ngSubmit)="signIn()" [formGroup]="loginForm" novalidate>
      <div class="toolbar-logo"></div>
      <ion-list class="login-inputs" lines="none" inset>
        <ion-item class="username">
          <ion-input
            id="username"
            type="text"
            id="username"
            class="username"
            formControlName="username"
            autocomplete="username"
            placeholder="Username"
          ></ion-input>
        </ion-item>
        <ion-item class="password">
          <ion-input
            id="password"
            class="password"
            id="password"
            placeholder="Password"
            type="password"
            formControlName="password"
            autocomplete="current-password"
          ></ion-input>
        </ion-item>
        <button class="primary button" type="submit">
          Login
        </button>
      </ion-list>
    </form>
  </div>
</ion-content>
