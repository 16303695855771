<ion-content>
  <ion-row>
    <ion-col class="team-sidebar">
      <app-team-listing-sidebar> </app-team-listing-sidebar>
    </ion-col>
    <ion-col class="user-profile-content">
      <ion-title>User Profile</ion-title>
      <form id="user-profile-form" [formGroup]="userProfileForm" novalidate>
        <h4 class="form-subheadline">
          <i class="icon-contact"></i>
          General Information
        </h4>
        <ion-list lines="none" inset>
          <ion-item class="first-name">
            <ion-label color="medium" position="stacked">First Name <span style="color:#f1624a">*</span></ion-label>
            <ion-input type="text" formControlName="userFirstName" inputCapitalizeFirst required></ion-input>
          </ion-item>
          <ion-item class="last-name">
            <ion-label color="medium" position="stacked">Last Name <span style="color:#f1624a">*</span></ion-label>
            <ion-input type="text" formControlName="userLastName" inputCapitalizeFirst required></ion-input>
          </ion-item>
          <ion-item class="user-phone-container">
            <ion-item class="user-phone-country-code" style="display: none;">
              <ion-label color="medium" position="stacked">+(1)</ion-label>
              <ion-input type="text" maxLength="2" formControlName="userPhoneCountryCode"></ion-input>
            </ion-item>
            <ion-item class="user-phone-area-code" style="display: none;">
              <ion-label color="medium" position="stacked">(###)</ion-label>
              <ion-input type="text" maxLength="3" formControlName="userPhoneAreaCode"></ion-input>
            </ion-item>
            <ion-item class="user-phone-number">
              <ion-label color="medium" position="stacked">Phone #</ion-label>
              <ion-input type="text" formControlName="userPhoneNumber"></ion-input>
            </ion-item>
          </ion-item>
          <ion-item class="user-spanish-speaking">
            <ion-label color="medium" class="spanish-label" position="stacked">Do you speak Spanish?</ion-label>
            <ion-radio-group formControlName="userSpeaksSpanish">
              <ion-item>
                <ion-radio value="1" class="spanish-speaking radio-button-yes"> </ion-radio>
              </ion-item>
              <ion-item>
                <ion-radio value="0" class="spanish-speaking radio-button-no"> </ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-item>
        </ion-list>
        <h4 class="form-subheadline">
          <i class="icon-birthday"></i>
          Get to know you...
        </h4>
        <ion-list class="get-to-know-you" lines="none">
          <ion-item class="user-dob">
            <ion-label color="medium" position="stacked">Birthday <span style="color:#f1624a">*</span></ion-label>
            <ion-input type="date" formControlName="userDob"></ion-input>
          </ion-item>
          <ion-item class="user-dessert">
            <ion-label color="medium" position="stacked">Favorite Dessert</ion-label>
            <ion-input type="text" formControlName="userFavoriteDessert"></ion-input>
          </ion-item>
        </ion-list>
        <ion-list lines="none" inset class="checkbox-list-container">
          <ion-item formGroupName="userInterests">
            <ion-label class="main-label-box" color="medium" position="stacked">
              Do any of the following apply to you?
            </ion-label>
            <ion-list class="checkboxes" lines="none" inset>
              <ion-item>
                <ion-checkbox formControlName="celebrity"></ion-checkbox>
                <ion-label>Met your favorite celebrity</ion-label>
              </ion-item>

              <ion-item>
                <ion-checkbox formControlName="helicopter"></ion-checkbox>
                <ion-label>Flown in a helicopter</ion-label>
              </ion-item>

              <ion-item>
                <ion-checkbox formControlName="kidney"></ion-checkbox>
                <ion-label>Donated a kidney</ion-label>
              </ion-item>

              <ion-item>
                <ion-checkbox formControlName="skydivedOrBungeed"></ion-checkbox>
                <ion-label>Skydived or bungee jumped</ion-label>
              </ion-item>
              <ion-item>
                <ion-checkbox formControlName="appearedOnTv"></ion-checkbox>
                <ion-label>Appeared on TV</ion-label>
              </ion-item>

              <ion-item>
                <ion-checkbox formControlName="janeAusten"></ion-checkbox>
                <ion-label>Read Pride & Prejudice</ion-label>
              </ion-item>

              <ion-item>
                <ion-checkbox formControlName="escargo"></ion-checkbox>
                <ion-label>Eaten Escargo</ion-label>
              </ion-item>

              <ion-item>
                <ion-checkbox formControlName="deployed"></ion-checkbox>
                <ion-label>Been deployed</ion-label>
              </ion-item>
              <ion-item>
                <ion-checkbox formControlName="instrument"></ion-checkbox>
                <ion-label>Played an instrument</ion-label>
              </ion-item>

              <ion-item>
                <ion-checkbox formControlName="seenTornado"></ion-checkbox>
                <ion-label>Seen a tornado</ion-label>
              </ion-item>
              <ion-item>
                <ion-checkbox formControlName="hitchhiked"></ion-checkbox>
                <ion-label>Hitchhiked</ion-label>
              </ion-item>
              <ion-item>
                <ion-checkbox formControlName="DND"></ion-checkbox>
                <ion-label>Played Dungeons & Dragons</ion-label>
              </ion-item>
            </ion-list>
          </ion-item>
        </ion-list>
        <div class="user-profile-more-container">
          <ion-list lines="none">
            <ion-item>
              <ion-label class="main-label-box" color="medium" position="stacked"
                >Anything else you want to share with the team?</ion-label
              >
              <ion-textarea formControlName="userAdditionalInfo"></ion-textarea>
            </ion-item>
          </ion-list>
        </div>
        <button class="save primary button" (click)="updateUserProfile()" type="submit">
          Save
        </button>
        <button class="cancel secondary button" (click)="cancelUpdateProfile()">
          Cancel
        </button>
      </form>
    </ion-col>
    <ion-col class="avatar-col">
      <div class="avatar-container">
        <app-user-avatar [user]="user"></app-user-avatar>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
